<template>
    <GmapMap
        ref="gmap"
        :center="center"
        :zoom="15"
        :options="mapOptions"
        map-type-id="terrain"
        style="width:100%; height: calc(100vh - 44px);" >
        <GmapMarker
            :key="m.id"
            v-for="m in markers"
            :position="m.position"
            :clickable="true"
            :draggable="false"
            @click="clickMapShop(m.position,m)" />
    </GmapMap>
</template>
<script>
export default {
    name:"Map",
    props:['selectshop'],
    data() {
        return {
            center: {
                lat:35.6812362,
                lng:139.7649361,
            },
            location:{
                lat:35.6812362,
                lng:139.7649361,
                error_code:-1
            },
            selectShop:this.selectshop,
            mapOptions:{
                mapTypeControl:false,
                streetViewControl:false,
                fullscreenControl:false,
                zoomControl:false
            },
            shoplist: [],
            markers:[],
            google: null,
        }
    },
    watch:{
        selectshop:{
            // 親側で店が変更された
            handler: function(newVal){
                // 地図の中心移動の値を変更
                if( newVal?.lat && newVal?.lng ) {
                    this.center.lat = parseFloat(newVal.lat);
                    this.center.lng = parseFloat(newVal.lng);
                }
            },
            deep: true,
            immediate: true
        },
    },
    mounted(){
    },
    async created() {
        this.$emit('setLoading', true);
        // ログイン情報を取得
        this.getUrlParam(); // URLパラメータの取得
        this.init = this.storageGet('*'); // ローカルストレージから情報を取得
      try {
        console.log('******** will get location ********');
        let location = await this.getLocation(); // 現在地の取得
        console.log('******** did get location ********');
        console.log(location);
        // API、LocalStorageから緯度経度を取れていない場合
        if( (location.lat === null || location.lat === undefined )
         || (location.lng === null || location.lng === undefined )  ) {
            // どちらかがNull または undefined の場合は位置座標の不備としてSIDの保持しているlat , lng を設定
            // let shopInfo = await this.getShopDetail(this.init.sid);
            let shopInfo = await this.getShopDetail(200042); // YAMAYA_EB-523 飲食-博多もつ鍋やまや 博多店
            this.center.lat = (shopInfo.shop.lat !== null)? parseFloat(shopInfo.shop.lat) : null;
            this.center.lng = (shopInfo.shop.lng !== null)? parseFloat(shopInfo.shop.lng) : null;
            // 自身の位置もSIDに従う
            this.location.lat = (shopInfo.shop.lat !== null)? parseFloat(shopInfo.shop.lat) : null;
            this.location.lng = (shopInfo.shop.lng !== null)? parseFloat(shopInfo.shop.lng) : null;
        }
        console.log('******** location2 ********');
        console.log(this.location);
        // 座標取得用の店舗一覧を取得(マーカー設定用)
        let resDetail = await this.getLocationShopDetail(null,this.location.lat,this.location.lng); // 端末のLocationがわかっているので近い順に店舗を取得
        resDetail.shop_list.forEach(shop => {
            this.shoplist.push( shop ); // 店舗リストへ挿入
        });
        // この時点でもLocationを取得できていない、または不明の場合は店舗リストの一件目を設定
        if( this.shoplist.length > 0 ) {
            let firstShop = this.shoplist[0]; //先頭を取得
            // Locationがない
            if( this.location.lat === null || this.location.lng === null ) {
                this.location.lat = firstShop.lat; // Location を先頭店舗
                this.location.lng = firstShop.lng;
                this.center.lat = firstShop.lat;
                this.center.lng = firstShop.lng;
            }
            // 先頭の店舗のsort_flgを見て、0の場合はID順、1の場合は距離順
            if( ( firstShop?.sort_flg !== null || firstShop?.sort_flg !== undefined ) && firstShop?.sort_flg === 0 )  {
                // ID順の場合は先頭の店舗のlat , lng にcenterを合わせる。
                this.center.lat = (firstShop.lat !== null)? parseFloat(firstShop.lat) : this.center.lat;
                this.center.lng = (firstShop.lng !== null)? parseFloat(firstShop.lng) : this.center.lng;
            }
        }
        let res = await this.getShopList(); // 店舗情報を取得
        this.$emit('screen_id' , (!res?.screen_id)? "" :res.screen_id); // 遷移用のIDを登録
        this.$emit('location',this.location);
        this.setShopMarker(); // 店舗の座標マーカーを設定
        this.$emit('setShopList',this.shoplist,this.markers); // 親コンポーネントへ値を設定
      } catch (e) {
        console.log(e);
      }
        this.$emit('setLoading', false);
    },
    methods: {
        getCurrentLocation() {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    function (position) {
                        const geo = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        }
                        resolve(geo)
                    }, function (error) {
                        reject(error)
                    });
                });
        },
        async getLocation() {
            var location = {
                lat: null,
                lng: null,
                error_code: -1,
            };
            if (this.init?.denied_use_gps) {
              console.log('******** !! denied_use_gps !! ********');
              return location;
            }
            try {
                location = await this.getCurrentLocation();
                // Map画面のCenterに持ってくる座標を設定
                this.center.lat = location.lat;
                this.center.lng = location.lng;
                // locationとして登録する位置を設定
                this.location.lat = location.lat;
                this.location.lng = location.lng;
                return location;
            } catch (e) {
                // APIでエラーである場合はLocalstorageから緯度経度を設定
                location.error_code = e.code; // APIのエラーコード
                location.lat = (this.init?.latitude !== null && this.init?.latitude !== undefined )? parseFloat(this.init?.latitude): null;
                location.lng = (this.init?.longitude !== null && this.init?.longitude !== undefined )? parseFloat(this.init?.longitude): null;
                // Map画面のCenterに持ってくる座標を設定
                this.center.lat = location.lat;
                this.center.lng = location.lng;
                // locationとして登録する位置を設定
                this.location.lat = location.lat;
                this.location.lng = location.lng;
                return location;
            }
        },
        setShopMarker(){
            if(!this.shoplist) return ;
            var i = 0;
            for ( var sKey in this.shoplist ) {
                let shop = this.shoplist[sKey];
                if( shop?.lat && shop?.lng ) {
                    // 緯度経度が両方あればマーカー用の設定を追加
                    this.markers[i] = {
                        id: shop.id ,
                        position:{
                            lat: parseFloat(shop.lat),
                            lng: parseFloat(shop.lng),
                        }
                    }
                    i++;
                }
            }
            this.markers.splice();//イベント発火
        },
        /**
         * SHOP一覧の取得
         * @return shop_list
         */
        getShopList(){
            var shoplist_req = { params:{
                'sid': this.init.sid,
                'device_uid': this.init.device_uid,
                'device_id': this.init.device_id,
                'os': this.init.os,
                'key': this.init.key,
                'location': 1,
                'feature_id': this.feature_id ?? 'info',
            }};
            try{
                var res = this.apiCallIfUnauthorizedReturnsErrorCode('/user/shops', shoplist_req);
                return res;
            } catch( error ) {
                this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
                return error;
            }
        },
        /**
         * 店舗詳細の取得
         * ログイン情報の所属するSID、自身の端末からの緯度経度をキーに店舗情報を取得する。
         * ※緯度経度を取得
         */
        getLocationShopDetail(shop_id,lat,lng){
            var shoplist_req = { params:{
                'sid': this.init.sid,
                'device_uid': this.init.device_uid,
                'device_id': this.init.device_id,
                'os': this.init.os,
                'key': this.init.key,
                'shop_id': (shop_id == null)? this.init.sid : shop_id,
                'lat': lat ,
                'lng': lng ,
            }};
            try{
                var resDetail = this.apiCall('/user/shop/shoplocation', shoplist_req);
                return resDetail;
            } catch( error ) {
                this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
                return error;
            }
        },
        /**
         * 店舗詳細の取得 ※緯度経度を取得
         */
        getShopDetail(shopId){
            var shoplist_req = { params:{
                'sid': this.init.sid,
                'device_uid': this.init.device_uid,
                'device_id': this.init.device_id,
                'os': this.init.os,
                'key': this.init.key,
                'location': 0,
                'feature_id': this.feature_id ?? 'info',
                'shop_id': shopId,
            }};
            try{
                var resDetail = this.apiCall('/user/shop/detail', shoplist_req);
                return resDetail;
            } catch( error ) {
                this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
                return error;
            }
        },
        /**
         * リスト取り出し
         */
        convList : function ( shop_list ) {
            var list = Array();
            if( !shop_list ) return [];
            shop_list.forEach(shop => {
                // CategoryとShopの判別
                if( shop?.list ) {
                    // list を持つ場合は2階層目のカテゴリに属するのでもう一階層下がる
                    let childShopList = shop?.list;
                    childShopList.forEach(child => {
                        if( child?.list ) {
                            // リストを持つ（子カテゴリ）かを確認し、もう一階層下がる
                            let grandchildList = child?.list;
                            grandchildList.forEach( grand => {
                                let chkshop = list.find((v) => v.id === grand.id);
                                if( !chkshop ) {
                                    grand.isActive = false; // 要素を追加
                                    list.push(grand);
                                }
                            });
                        } else {
                            // カテゴリではないので店舗情報
                            let chkshop = list.find((v) => v.id === child.id);
                            if( !chkshop ) {
                                child.isActive = false; // 要素を追加
                                list.push(child);// IDが重複していなければ店舗リストに追加
                            }

                        }
                    });
                }
                // parent_category_order,follow_statusを持つ場合は店舗情報
                if( shop?.parent_category_order && shop?.follow_status ) {
                    let chkshop = list.find((v) => v.id === shop.id);
                    if( !chkshop ) {
                        shop.isActive = false; // 要素を追加
                        list.push(shop);
                    }
                }
            });
            return list;
        },
        clickMapShop(position,shopid){
            this.center = position; // MAPの中心に来る座標をcenterに設定
            let shop = this.shoplist.find( (v) => v.id === shopid.id );
            this.$emit('touchChildMarker',shop);
        },
    }
}
</script>