<template>
	<v-content class="calendar">
		<SelectedShop v-if="init.branch_flg == 1" :selected_shop_name="selected_shop" :router_param="{ name: 'calendar_shops', query: { feature_id: 'calendar'}}" ></SelectedShop>

    <div>
      <FullCalendar :options="calendarOptions"></FullCalendar>
    </div>

    <v-bottom-sheet v-model="sheet">
      <v-sheet class="text-center pb-3" color="#EFEFF4">
        <v-row>
          <v-col style="background-color:#EFEFF4; height:34px;" class="text-center pa-0" cols="12" @click="sheetclose">
            <img src="@/assets/calendar/arrow_down.svg">
          </v-col>
        </v-row>
        <div class="px-2">
          <p v-if="sheetDays" class="headday text-left">{{sheetDays}}</p>
          <div class="daywrap">
            <div v-if="sheetArray">
              <div style="position:relative;" v-for="(item, i) in sheetArray" :key="i">
                  <v-row align="center" @click.stop="toDetail(item.kbn, item.id)">
                    <v-col cols="2">
                      <v-avatar :class="item.className" size="18">
                      </v-avatar>
                      <v-badge v-if="item.new_flg" color="#FF5256" offset-x="8" content="N" class="newmark"></v-badge>
                    </v-col>
                    <v-col cols="10 text-left">
                      <v-chip x-small class="mb-1 eventchip" color="#9D9D9D" outlined>
                        <span v-if="item.kbn == 1">イベント</span><span v-if="item.kbn == 2">お知らせ</span><span v-if="item.kbn == 3">スタンプ</span>
                      </v-chip>
                      <p class="body-2 font-weight-bold mb-2">{{item.title}}</p>
                      <p class="overline mb-0">{{item.start}}<span v-if="item.enddate != item.start && item.end != null && item.kbn != 3">〜{{item.enddate}}</span></p>
                      <p class="overline mb-0">対象店舗：{{item.shop_name}}</p>
                      <v-chip v-if="item.is_read == 0" x-small class="read" color="#9D9D9D" dark>未読</v-chip>
                    </v-col>
                  </v-row>

                <v-divider class="my-0 mx-3"></v-divider>
              </div>
            </div>

            <div v-else>
              <p class="body-2 mt-10" v-if="sheetloading == false">
                対象のデータはありません。
              </p>
            </div>

            <v-overlay absolute :value="sheetloading">
              <v-progress-circular :size="40" :width="2" class="maincolor" indeterminate></v-progress-circular>
            </v-overlay>
          </div>

        </div>

      </v-sheet>
    </v-bottom-sheet>

    <v-footer v-if="footerflg" fixed padless height="34" color="#EFEFF4" class="sheetopenbtn">
      <v-row>
        <v-col class="text-center pa-0" cols="12" @click="sheetopen">
          <img src="@/assets/calendar/arrow_up.svg">
        </v-col>
      </v-row>
    </v-footer>

    <v-overlay :value="loading">
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>
	</v-content>

</template>

<script>
import SelectedShop from '@/components/selected_shop.vue'
import FullCalendar from '@fullcalendar/vue'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import moment from "moment"
import jaLocale from "@fullcalendar/core/locales/ja"
export default {
	components: {
    SelectedShop,
    FullCalendar
	},
  data() {
    return {
      loading: false,
      sheetloading: false,
      sheet: false,
      sheetArray: [],
      footerflg: true,
      sheetDays: null,
      calendarOptions: {
        locale: jaLocale,
        plugins: [ dayGridPlugin, interactionPlugin ],
        contentHeight: 440,
        selectable: false,
        initialView: 'dayGridMonth',
        dateClick: this.handleDateClick,
        eventClick: this.eventclick,
        headerToolbar: {
          start: 'prev',
          center: 'title',
          end: 'next'
        },
        buttonIcons: {
          prevYear: 'left-single-arrow',
          nextYear: 'right-single-arrow'
        },
        events: []
      },
    }
  },
  created () {
    this.getUrlParam()
    this.init = this.storageGet('*')

    var selected_shop = this.getUrlGetParam('selected_shop');

    if (selected_shop) {
        this.selected_shop = selected_shop
    } else {
        this.selected_shop = '全て'
    }

    this.Getdata(1)

  },
  mounted () {

  },
  computed: {

  },
	methods: {
    Getdata: async function(get_type, day = null) {
      var shop_id = this.getUrlGetParam('shop_id');

      if(!shop_id){
        shop_id = this.init.sid
      }

      var year = null
      var month = null
      var date = null

      if(day){
        year = moment(day).format('YYYY')
        month = moment(day).format('M')
        date = moment(day).format('D')
      }

      var params = { params:{
        'shop_id': shop_id,
        'type': 2,
        'year': year,
        'month': month,
        'date': date
      }}

        // カレンダー情報取得API
        var res = await this.apiCall('/notices/list2', params, 'get');
        //console.log(res)
        //if (!res) return false;
        if(res.data != null){
          res.data.forEach( function( value ) {
            if(value.end){
              value["enddate"] = value.end
              value.end = moment(value.end).add(1, 'd').format('YYYY-MM-DD')
            }
          });
        }

        if(get_type == 1){
          this.calendarOptions.events = res.data
        }else{
          if(res.data != null){
            res.data.forEach( function( value ) {
              if(value.start){
                value.start = moment(value.start).format('YYYY年MM月DD日')
              }
              if(value.end){
                value.enddate = moment(value.enddate).format('YYYY年MM月DD日')
                //value.end = moment(value.end).add(1, 'd').format('YYYY年MM月DD日')
              }
            });
          }
          this.sheetArray = res.data
          this.sheetloading = false
        }
    },
    handleDateClick: function(info) {
      this.sheetDays = moment(info.dateStr).format('YYYY年MM月DD日')
      if(this.sheetArray){
        this.sheetArray.splice(0);
      }

      this.sheet = true
      this.footerflg = false
      this.sheetloading = true

      this.Getdata(2, info.dateStr)

    },
    eventclick: function(info) {
      info.jsEvent.preventDefault();
    },
    /*
    eventDataTransform: function(event) {
      if (event.end) {
        //var timestamp = new Date(Date.parse(event.end)).getTime();
        //event.end = moment(new Date(timestamp + 60*60*24*1000)).format('YYYY-MM-DD');
        event.end = moment(event.end).add(1, 'd').format('YYYY-MM-DD')
      }
      return event;
    },
    */
    sheetopen: function() {
      this.sheet = true
      this.footerflg = false
    },
    sheetclose:function() {
      this.sheet = false
      this.footerflg = true
    },
    toDetail: function(kbn, id){
      switch (kbn) {
        case 1:
          this.routerBindTo({ name: 'notification_detail', query: { id: id, contents_type: 1 }})
          break
        case 2:
          this.routerBindTo({ name: 'notification_detail', query: { id: id, contents_type: 2 }})
          break
        case 3:
          this.routerBindTo({ name: 'stamp_memolist', query: { stamp_id: id, shop_id: this.init.shop_id }})
          break
        default:
          return false
      }
    }
	}
}
</script>

<style scoped>
.v-application .calendar >>> a {
  color: #333333;
  font-size: 11px;
  text-align: center;
}
.calendar >>> .fc .fc-toolbar {
  justify-content: center;
}
.calendar >>> .fc .fc-button-primary {
  background-color: inherit;
  border-color: inherit;
  border: none;
  color: #333;
}
.calendar >>> .fc .fc-button-primary .fc-icon {
  display: none;
}
.calendar >>> .fc .fc-button-primary.fc-prev-button::before {
  content: "◀";
  font-size: 11px;
  line-height: 16px;
  color: #9D9D9D
}
.calendar >>> .fc .fc-button-primary.fc-next-button::before {
  content: "▶";
  font-size: 11px;
  line-height: 16px;
  color: #9D9D9D
}
.calendar >>> .fc-toolbar-title {
  font-size: 16px;
  line-height: 16px;
}
.calendar >>> .fc .fc-button {
  padding: 0 1em 0.15em;

}
.calendar >>> .fc .fc-button:focus {
  box-shadow: none;
}
.calendar >>> .fc-header-toolbar {
  margin-bottom: 0;
  padding: 10px 0;
  background-color: #fff;
}
.calendar >>> .fc .fc-daygrid-day-top {
  flex-direction: column;
}
.calendar >>> .fc-day-sun a {
  color: #FE5355;
}
.calendar >>> .fc-day-sat a {
  color: #2E84CC;
}
.calendar >>> .fc-scrollgrid > tbody {
  box-shadow:0px 8px 8px -5px #ccc inset;
}
.calendar >>> .fc-theme-standard th {
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: #fff;

}
.calendar >>> .fc-theme-standard .fc-scrollgrid {
  border-top: none;
}
.calendar >>> .fc-theme-standard td, .fc-theme-standard th {
  border: none;
}
.calendar >>> .fc .fc-daygrid-day-number {
  padding: 8px 4px;
  font-weight: 700;
}
.calendar >>> .fc-col-header-cell {
  padding: 5px 0;
}
.calendar >>> .fc .fc-daygrid-event {
  height: 4px;
  border-radius: 4px;
}
.calendar >>> .fc .fc-daygrid-day-events {
  pointer-events: none;
}
.calendar >>> .fc-event-title.fc-sticky {
  display: none;
}
.calendar >>> .fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end.fc-event-past {
  width: 4px;
}
.calendar >>> .fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start {
  margin-right: 25px;
}
.calendar >>> .fc-direction-ltr .fc-daygrid-event.fc-event-start, .fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin-left: 25px;
}
.calendar >>> .fc-h-event.stamp, .calendar >>> .fc-h-event.event {
  background-color: #FED85B;
  border-color: #FED85B;
}
.calendar >>> .fc-h-event.notice {
  background-color: #7AC5A6;
  border-color: #7AC5A6;
}

.v-avatar.event, .v-avatar.stamp {
  background-color: #FED85B;
}
.v-avatar.notice {
  background-color: #7AC5A6;
}

.sheetopenbtn {
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
}

.daywrap {
  background-color: #fff;
  border-radius: 14px;
  height: 220px;
  padding: 5px;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  position: relative;
}
.newmark {
  transform: scale(0.7,0.7);
  transform-origin:left center;
}
.eventchip {
  transform: scale(0.9,0.9);
  transform-origin:left top;
}
.headday {
  margin: -15px 0 5px;
  font-size: 11px;
}
.read {
  bottom: 10px;
  right: 15px;
  position: absolute;
}

</style>
