<template>
  <div>
    <v-dialog v-model="localValue" class="test" max-width="260">
      <v-card class="dia_bg">
        <v-card-title style="font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;">{{title}}</v-card-title>
        <v-card-text>{{text}}</v-card-text>
        <v-card-actions v-if="deleteBtnFlg">
          <v-btn text @click="setDelBtnChange()">削除する</v-btn>
          <v-btn text @click="localValue = false"
            >キャンセル</v-btn
          >
        </v-card-actions>
        <v-card-actions v-else>
          <v-btn text style="width: 100%;" @click="localValue = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: ['deletingName', 'title', 'text', 'deleteBtnFlg'],

  data: () => ({
    localValue: false,
  }),
  methods: {
    setDelBtnChange() {
        this.localValue = false;
        this.$emit('set-del-btn-change')
    },
    open() {
        this.localValue = true;
    },
  },
}
</script>
<style scoped>
.v-card__actions > .v-btn.v-btn {
  padding: 0 !important;
  width: 50%;
  border-top: 1px solid gray;
  border-right: 1px solid gray;
  border-radius: 0px;
  color: #3090FA !important;
}
.v-application--is-ltr .v-card__actions > .v-btn.v-btn + .v-btn {
  margin-left: 0px;
  border-right: 0 !important;
}
.v-card__actions {
  padding: 0 !important;
}
.dia_bg {
  background: #E7E7E8 !important;
}
.v-dialog > .v-card > .v-card__title {
  justify-content: center;
  font-size: 0.925rem;
  font-weight: bolder;
  font-family: '游ゴシック体';
  padding: 16px 24px 0px;
}
.v-card__subtitle, .v-card__text {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  text-align: center;
  color: black !important;
}
.v-card__text {
  white-space: pre-wrap;
}
</style>
<style>
.v-dialog {
  border-radius: 15px !important;
}
</style>