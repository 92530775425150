<template>
  <div>
    <v-dialog v-model="localValue" class="test" max-width="260">
      <v-card class="dia_bg">
        <v-card-title>選択したトークを削除しますか？</v-card-title>
        <v-card-text>削除したトークは元に戻せません。<br>
          相手側の端末からは削除されません。
        </v-card-text>
        <v-card-actions>        
          <v-btn text @click="deleteRecentShop()">はい</v-btn>
          <v-btn text @click="localValue = false"
            >いいえ</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  data: () => ({
    localValue: false,
  }),
  methods: {
    deleteRecentShop() {
        this.localValue = false;
        this.$emit('delete-recent-shop')
    },
    open() {
        this.localValue = true;
    },
  },
}
</script>
<style scoped>
.v-card__actions > .v-btn.v-btn {
  padding: 0 !important;
  width: 50%;
  border-top: 1px solid gray;
  border-right: 1px solid gray;
  border-radius: 0px;
  color: #3090FA !important;
}
.v-application--is-ltr .v-card__actions > .v-btn.v-btn + .v-btn {
  margin-left: 0px;
  border-right: 0 !important;
}
.v-card__actions {
  padding: 0px;
}
.dia_bg {
  background: #E7E7E8 !important;
}
.v-dialog > .v-card > .v-card__title {
  font-size: 0.925rem;
  font-weight: bolder;
  font-family: '游ゴシック体';
  padding: 16px 24px 0px;
  text-align: center;
}
.v-card__subtitle, .v-card__text {
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 0.0071428571em;
  text-align: center;
  color: black !important;
  text-align: center;
}
</style>
<style>
.v-dialog {
  border-radius: 15px !important;
}
</style>