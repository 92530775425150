<template>
  <v-main>
    <div>
      <v-tabs
        grow
        fixed
        center-active
        v-model="selectedTab"
        style="border-bottom:1px solid #E6E6E6"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#area" class="tabs-left" @click="setScrollOn()">エリアから</v-tab>
        <v-tab href="#shops" class="tabs-center" @click="setScrollOff()">現在地から</v-tab>
        <v-tab href="#followShops" class="tabs-right" :disabled="!authorized" @click="setScrollOn()">お気に入り</v-tab>
      </v-tabs>
      <!-- エリアから -->
      <v-tabs-items class="" v-model="selectedTab" style="">
        <v-tab-item class="" value="area" style="min-height: 100%">
          <div class="area-list" v-for="area in area_list" :key="area.state">
            <div
             @click="toggleArea(area.state, $event)"
             :class="activeState === null || activeState == area.state ? 'display-block area-active' : 'display-none'"
             style="border-bottom: 1px solid #e6e6e6 !important;"
            >
              <div class="area-block">
                <div class="area" style="width: 85%;padding: 15px;">
                  {{ area.area_name }}&nbsp;&nbsp;({{area.count}})
                </div>
                <div class="area_icon" style="width: 15%;padding: 15px;">
                  <v-icon v-if="activeState === null">mdi-chevron-right</v-icon>
                  <v-icon v-else>mdi-chevron-left</v-icon>
                </div>
              </div>
            </div>
            <div class="area-list-item" v-for="(area_shops, category_name) in area_shop_list[area.state]" :key="category_name">
              <div class="category_name" v-on:click.stop=""> {{ category_name.split(" - ", 2)[1] }} ({{area_shops.length}})</div>
              <div class="area-list-item area-list-item-shop"
                v-for="n in area_shops"
                :key="n.id"
                v-on:click="touchShop(n)">
                <div class="shop_detail">
                  <span class="shop_category_name">
                    {{ category_name.split(" - ", 2)[1] }}
                    <span v-if="n.parent_category_name == '飲食'" class="genre01">{{ n.parent_category_name }}</span>
                    <span v-else-if="n.parent_category_name == '物販'" class="genre02">{{ n.parent_category_name }}</span>
                    <span v-else class="genre01">{{ n.parent_category_name }}</span>
                  </span>
                  <br />
                  <span class="shop_name">{{ n.name }}</span>
                  <br />
                  <span class="address">{{ n.address1 }} {{ n.address2 }}</span>
                </div>
                <div class="shop_detail_icon">
                  <v-icon>mdi-chevron-right</v-icon>
                </div>
              </div>
            </div>
          </div>
          <v-overlay :value="loading">
            <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
          </v-overlay>
        </v-tab-item>
      </v-tabs-items>
      <!-- 現在地から -->
      <v-tabs-items class="" v-model="selectedTab" style="background-color: #f9f9f9">
        <v-tab-item class="" value="shops" style="min-height: 100%">
          <div class="map">
            <Map
              :selectshop="selectShop"
              v-on:touchChildMarker="touchMarker"
              @setShopList="setShopList"
              @recommend_shop_list="setRecommend_shop_list"
              @screen_id="setScreenId"
              @location="setLocation"
              @setLoading="setLoading"
            ></Map>
          </div>
          <div class="slider-wrap">
            <slick
             ref="slick"
             :options="slickOptions"
            >
              <v-card
                v-for="n in shoplist"
                :key="n.id"
                class="ma-1"
                max-height="300px"
                max-width="300px"
                min-width="300px"
                :ripple="false"
                v-on:click.prevent="touchMapAction(n)"
              >
                  <v-card-text class="" style="color: #333; line-height:initial;">
                    <!-- 店舗名 -->
                    <v-row no-gutters align="center" class="pt-0 pb-0">
                      <div class="map-category-img">
                        <img v-if="n.image_path" :src="n.image_path" style="height:60px; width:60px;">
                        <img v-else :src="category_img[n.category_name]" style="height:60px; width:60px;">
                      </div>
                      <div class="map-shop-name">
                        <span style="display:block; font-size:0.75rem;">
                          {{ n.category_name }}
                        </span>
                        <span
                          style="display:block; line-height:1.25 !important; font-size:1rem;"
                        >{{ n.name.value }}</span>
                      </div>
                    </v-row>
                    <v-row class="mt-0" style="margin-bottom:0.625rem;">
                      <v-col class="pa-0" style="height:2rem;">
                        <span style="font-size:0.75rem">{{ n.address1.value }}</span><br>
                        <span style="font-size:0.75rem">{{ n.address2.value }}</span>
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions class="card-btn-style" justify="center">
                    <v-row>
                      <v-col cols="" class="pa-0 text-center">
                        <v-btn
                          rounded
                          small
                          depressed
                          min-width="100"
                          class="cardButton maincolor-bg"
                          style="height:1.75rem; font-size:0.75rem; width: 4rem;"
                          v-on:click.stop="touchShop(n)"
                          >店舗詳細</v-btn
                        >
                      </v-col>
                      <!-- お気に入り -->
                      <v-col cols="5" class="pa-0 text-center" v-if="authorized">
                        <button type="button" class="b_fav on" v-if="authorized && isFollowCheck(n.id)" @click.prevent="followSet(n.name.value, n.id)">
                          <span class="icon i_star"></span>
                        </button>
                        <button type="button" class="b_fav" v-else-if="authorized && !isFollowCheck(n.id)" @click.prevent="followSet(n.name.value, n.id)">
                          <span class="icon i_star"></span>
                        </button>
                      </v-col>
                    </v-row>
                  </v-card-actions>
                </v-card>
            </slick>
          </div>
          <v-overlay :value="loading">
            <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
          </v-overlay>
        </v-tab-item>
      </v-tabs-items>
      <!-- お気に入り -->
      <v-tabs-items class="" v-model="selectedTab" style="">
        <v-tab-item
          class=""
          value="followShops"
          style="min-height: 100%"
        >
          <v-list class="pa-0">
            <v-list-item-group>
              <v-list-item
                class="fav-item"
                v-for="r in recommend_shop_list"
                :key="r.id"
              >
                <v-list-item-content class="pa-0" v-on:click.stop="touchShop(r)">
                  <v-row align-content="center">
                    <v-col class="pa-0" style="width:4.5rem; flex-grow:initial; margin:0 1rem 0 0;">
                      <img v-if="r.image_path" :src="r.image_path" style="height:60px; width:60px;">
                      <img v-else :src="category_img[r.category_name]" style="height:60px; width:60px;">
                      <br>
                    </v-col>
                    <v-col class="pa-0">
                      <span style="display:block; margin-bottom: 0.25rem; line-height:1.4;">
                        <span class="shop_category_name">
                          {{ r.category_name }}
                          <span v-if="r.parent_category_name == '飲食'" class="genre01">{{ r.parent_category_name }}</span>
                          <span v-else-if="r.parent_category_name == '物販'" class="genre02">{{ r.parent_category_name }}</span>
                          <span v-else class="genre01">{{ r.parent_category_name }}</span>
                        </span>
                      </span>
                      <span style="display:block; line-height:1.25 !important; font-size:0.875rem; font-weight:bold; margin-bottom:0.5rem;">
                        {{ r.name }}
                      </span>
                      <v-btn
                        rounded
                        small
                        depressed
                        class="shop-detail-btn"
                        :disabled="isBtnDisabled"
                        v-on:click.stop="clickRoute(r.id)"
                        >経路</v-btn
                      >
                      <v-btn
                        rounded
                        small
                        depressed
                        class="shop-detail-btn"
                        :disabled="isBtnDisabled"
                        v-on:click.stop="toHref(r.reserve_url[0].url)"
                        v-if="r.reserve_url.length > 0"
                        >Web予約</v-btn
                      >
                    </v-col>
                    <v-col cols="1" class="pa-0 d-flex align-center arrow-r">
                      <span class="icon i_arrow_r"></span>
                    </v-col>
                  </v-row>
                </v-list-item-content>
              </v-list-item>

            </v-list-item-group>
          </v-list>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </v-main>
</template>
<script>
import Map from "@/views/shop/map.vue";
import Slick from "vue-slick";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
export default {
  name: "shopBase",
  components: {
    Map,
    Slick,
},
  data: () => ({
    // tabs: "shops",
    shoplist: [],
    makers: [],
    recommend_shop_list: [],
    area_list: [],
    area_shop_list: {},
    selectShop: null,
    sidShop: null,
    screen_id: null,
    result: null,
    model: null,
    followDisableBtn: {
      flg: "0",
      img: require("@/assets/follow-map/followbtn.svg"),
      alt: "フォローする。",
    },
    followBtn: {
      flg: "1",
      img: require("@/assets/follow-map/followbtn_on.svg"),
      alt: "フォロー解除",
    },
    noImage: {
      img: require("@/assets/nophoto.png"),
      alt: "イメージファイルが取得出来ませんでした。",
    },
    image: {
      img: null,
    },
    isLogin: true,
    location: { lat: 0, lng: 0 },
    authorized: false,
    category_img: {
      "博多もつ鍋やまや": require("@/assets/shop_logo/logo_motsunabe.svg"),
      "めんたいBASE": require("@/assets/shop_logo/logo_mentaibase.jpg"),
      "焼肉やまや": require("@/assets/shop_logo/logo_yakiniku.jpg"),
      "博多やきとりNICK": require("@/assets/shop_logo/logo_nick.jpg"),
      "博多天ぷらやまや": require("@/assets/shop_logo/logo_hakatatenpurayamaya.png"),
      "ごはんとわたし": require("@/assets/shop_logo/logo_gohantowatashi.jpg"),
      "博多めんたいやまや食堂": require("@/assets/shop_logo/logo_shokudo.jpg"),
      "博多屋台DELIやまや": require("@/assets/shop_logo/logo_deliyamaya.jpg"),
      "博多やまや": require("@/assets/shop_logo/logo_harajyuku.jpg"),
      "炉ばた焼やまや": require("@/assets/shop_logo/logo_robatayaki.jpg"),
      "別邸": require("@/assets/shop_logo/logo_bettei.jpg"),
      "カフェ＆バー": require("@/assets/shop_logo/logo_yakuin3.jpg"),
      "マルシェ": require("@/assets/shop_logo/logo_kajyukura.png"),
      "やまや総本店": require("@/assets/shop_logo/sohonten.png"),
      "Yamaya Factory Terrace": require("@/assets/shop_logo/yamayafactory.png"),
      "直営店": require("@/assets/shop_logo/logo_white.png"),
      "YAMAYA BASE": require("@/assets/shop_logo/yamayabase.png"),
      "果樹蔵": require("@/assets/shop_logo/logo_kajukura.png"),
    },
    activeState: null,
    slickOptions: {
      infinite: false,
      autoplay: false,
      centerMode: true,
      centerPadding: "35px",
      speed: 200,
      slidesToShow: 1,
      dots: false,
      arrows: false,
      vertical: false,
    },
    isBtnDisabled: false,
    isAreaShopViewFlg: false,
    loading: false,
  }),
  computed: {
    selectedTab: {
      get() {
        return this.$store.state.selectedShopTab;
      },
      set(value) {
        this.$store.commit('setSelectedShopTab', value);
      }
    },
    shopWithImage: function () {
      return function (id) {
        let shop = this.shoplist.find((v) => v.id == id);
        if (shop && shop.image_path) {
          return require(`${shop.image_path}`);
        } else {
          return this.noImage.img;
        }
      };
    },
  },
  mounted () {
    if ( this.selectedTab === 'area' ) {
        this.setScrollOn();
    } else if ( this.selectedTab === 'shops' ) {
        this.setScrollOff();
    } else if ( this.selectedTab === 'followShops' ) {
        this.setScrollOn();
    } else {
        this.setScrollOff();
    }
  },
  async created() {
    this.getUrlParam(); // URLパラメータの取得
    this.init = this.storageGet("*"); // ローカルストレージから情報を取得

    try {
      // アクセストークンチェックAPI
      const token_check_req = {};
      await this.apiCallCustomerPF(
        "/access_token/check/" + this.init.device_uid,
        token_check_req
      );
      this.authorized = true;
    } catch (e) {
      console.log(e);
    }

    // お気に入り店舗一覧取得
    let recommendShops = await this.getRecommendShopList();
    this.recommend_shop_list = recommendShops.recommend_shop_list;

    // エリア一覧取得
    let res = await this.getAreaList();
    this.area_list = res.area_list;

    // 遷移用のIDを登録
    this.setScreenId(res.screen_id);

    // タブの設定を上書き
    let openTab = this.storageGet("active_tab");
    if ( openTab ) {
      // 開くタブ履歴がある場合はそれに従う
      if ( openTab === 'area' ) {
        this.selectedTab = openTab;
        let openState = this.storageGet("active_state"); // エリア情報を開閉する為コードを取得
        await this.openArea(openState);
        this.storageDelete("active_state");
      } else if ( openTab === 'shops' ) {
        this.selectedTab = openTab;
      } else if ( openTab === 'followShops' ) {
        this.selectedTab = openTab;
      } else {
        this.selectedTab = 'shops';
      }
    } else {
      this.selectedTab = 'shops';
    }
    this.storageDelete("active_tab");
  },
  watch: {
    recommend_shop_list: {
      handler: function (newVal) {
        this.recommend_shop_list = newVal;
      },
      deep: true,
      immediate: true,
    },
    screen_id: {
      handler: function (newVal) {
        this.screen_id = newVal;
      },
      deep: true,
      immediate: true,
    },
    location: {
      handler: function (newVal) {
        this.location = newVal;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleTouchMove: function(e) {
      e.preventDefault();
    },
    setScrollOn() {
      document.removeEventListener('touchmove', this.handleTouchMove, {passive: false});
    },
    setScrollOff() {
      document.addEventListener('touchmove', this.handleTouchMove, {passive: false});
    },
    toHref(url) {
      var req = {
        destination: url,
        transition_type: 4,
      }
      if (!(this.screenTransition(req))) {
        this.callDialog('遷移エラー', '遷移情報が不正です。再度アクセスして下さい。', 2);
      }
    },
    reInit() {
      this.$refs.slick.destroy();
      this.$nextTick(() => {
          this.$refs.slick.create();
      });
    },
    async clickRoute(id) {
      //        var shop = this.recommend_shop_list.find((v) => v.id === id);
      var shop = this.shoplist.find((v) => v.id === id);
      if (!shop) {
        let reShopList = await this.getLocationShopDetail();
        shop = reShopList.shop_list.find((v) => v.id === id);
        if(!shop) return;
      }
      // 内部ブラウザ起動
      // window.location.href = "https://www.google.com/maps/dir/?api=1&origin="+ this.location.lat +","+ this.location.lng +"&destination="+shop?.lat +","+shop?.lng;
      // 外部ブラウザ起動
      var req = {
        destination:
          "https://www.google.com/maps/dir/?api=1&origin=" +
          this.location.lat +
          "," +
          this.location.lng +
          "&destination=" +
          shop?.lat +
          "," +
          shop?.lng,
        transition_type: 4,
      };
      if (!this.screenTransition(req)) {
        console.log("error");
      }
    },
    async clickTel(id) {
      var shop = this.shoplist.find((v) => v.id === id);
      if (!shop) {
        let reShopList = await this.getLocationShopDetail();
        shop = reShopList.shop_list.find((v) => v.id === id);
        if(!shop) return;
      }
      window.location.href = "tel:" + shop.tel.value;
    },
    async getLocationShopDetail(){
      var shoplist_req = { params:{
          'sid': this.init.sid,
          'device_uid': this.init.device_uid,
          'device_id': this.init.device_id,
          'os': this.init.os,
          'key': this.init.key,
          'shop_id': this.init.sid,
      }};
      try{
          var resDetail = this.apiCall('/user/shop/shoplocation', shoplist_req);
          return resDetail;
      } catch( error ) {
          this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
          return error;
      }
    },
    setShopList(list, maker) {
      this.shoplist = list;
      this.makers = maker;
      this.shoplist.splice();
      this.reInit(); // スライダーを再読み込み
    },
    setRecommend_shop_list(list) {
      this.recommend_shop_list = list;
    },
    setScreenId(screen_id) {
      this.screen_id = screen_id;
    },
    setLocation(location) {
      this.location = location;
    },
    setLoading(isLoading) {
      this.loading = isLoading;
    },
    /**
     * お気に入り店舗一覧の取得
     */
    getRecommendShopList() {
      var shoplist_req = {
        params: {
          sid: this.init.sid,
          device_uid: this.init.device_uid,
          device_id: this.init.device_id,
          os: this.init.os,
          key: this.init.key,
          location: 0,
          feature_id: this.feature_id ?? "info",
        },
      };
      try {
        var res = this.apiCallIfUnauthorizedReturnsErrorCode(
          "/user/recommend-shops",
          shoplist_req
        );
        return res;
      } catch (error) {
        this.callDialog(
          "通信エラー",
          "ネットワークの状態が不安定です。再度お試しください",
          3
        );
        return error;
      }
    },
    /**
     * エリア一覧の取得
     */
    getAreaList() {
      var arealist_req = {
        params: {
          sid: this.init.sid,
          device_uid: this.init.device_uid,
          device_id: this.init.device_id,
          os: this.init.os,
          key: this.init.key,
          feature_id: this.feature_id ?? "info",
        },
      };
      try {
        var res = this.apiCallIfUnauthorizedReturnsErrorCode(
          "/user/shop-areas",
          arealist_req
        );
        return res;
      } catch (error) {
        this.callDialog(
          "通信エラー",
          "ネットワークの状態が不安定です。再度お試しください",
          3
        );
        return error;
      }
    },
    /**
     * エリア開閉
     */
    async toggleArea(state, event) {
      this.loading = true;
      this.activeState = this.activeState === null ? state : null;
      // if (state in this.area_shop_list) {
      //   // already get
      //   return;
      // }
      // if (!event.currentTarget.classList.contains("v-list-item--active")) {
      if (!this.isAreaShopViewFlg) {
        console.log(event);
        // when open
        let res = await this.getAreaShopList(state);
        //   this.$set(this.area_shop_list,  res.shop_list);
        let area_shop_list = {};
        area_shop_list[state] = res.shop_list;
        this.area_shop_list = area_shop_list;
        this.isAreaShopViewFlg = true;
      } else {
        this.isAreaShopViewFlg = false;
        this.area_shop_list = {};
      }
      this.loading = false;
    },
    /**
     * 初期処理用の開閉処理
     * @param {*} state
     */
    async openArea(state) {
      this.loading = true;
      this.activeState = this.activeState === null ? state : null;
      if ( state === null || state === undefined ) return;
      this.area_shop_list = {};
      let res = await this.getAreaShopList(state);
      let area_shop_list = {};
      area_shop_list[state] = res.shop_list;
      this.area_shop_list = area_shop_list;
      this.isAreaShopViewFlg = true;
      this.loading = false;
    },
    /**
     * エリア展開時に店舗一覧取得
     */
    async getAreaShopList(state) {
      var shoplist_req = {
        params: {
          sid: this.init.sid,
          device_uid: this.init.device_uid,
          device_id: this.init.device_id,
          os: this.init.os,
          key: this.init.key,
          feature_id: this.feature_id ?? "info",
          state: state,
        },
      };
      try {
        var res = this.apiCallIfUnauthorizedReturnsErrorCode(
          "/user/area-shops",
          shoplist_req
        );
        return res;
      } catch (error) {
        this.callDialog(
          "通信エラー",
          "ネットワークの状態が不安定です。再度お試しください",
          3
        );
        return error;
      }
    },
    touchShop(shop) {
      // アクティブなタブ名と付帯情報をstorageに格納
      // タブの設定を上書き
      this.storageSave(JSON.stringify({
        active_tab: this.selectedTab
      }));
      // 開くタブ履歴がある場合はそれに従う
      if ( this.selectedTab === 'area' ) {
        this.storageSave(JSON.stringify({
          active_state: this.activeState
        }));
      }
      this.setScrollOn();
      this.selectShop = shop;
      this.$router.push({
        name: this.screen_id,
        query: { shop_id: shop.id, selected_shop: shop },
      });
    },
    touchMapAction(shop) {
      this.selectShop = shop;
    },
    touchMarker(shop) {
      this.touchShop(shop);
    },
    async followSet(name, shopid) {
      let fshop = this.recommend_shop_list.find((v) => v.id === shopid); // お気に入りリストを確認
      var flg = 1; // 存在していない場合は追加なのでDEFAULTは1
      if (fshop?.id) {
        flg = 0; // 存在しているので削除
      }
      try {
        /* 店舗フォローAPIを叩く */
        var req = {
          shop_id: shopid,
          flg: flg,
        };
        var follow_res = await this.apiCallIfUnauthorizedReturnsErrorCode(
          "/user/favorite",
          req,
          "post"
        );
        // Responseを返却されていないor401or701の場合
        if (!follow_res || follow_res === 401 || follow_res === 701) {
          return;
        }

        // お気に入り店舗一覧更新
        let recommendShops = await this.getRecommendShopList();
        this.recommend_shop_list = recommendShops.recommend_shop_list;
      } catch (e) {
        return;
      }
    },
    /**
     * フォロー済みかのチェック
     */
    isFollowCheck(shopId) {
      let followShop = this.recommend_shop_list.find((v) => v.id == shopId);
      if (!followShop) {
        return false;
      } else {
        return true;
      }
    },
    shopImage(path) {
      if (path) {
        return `${path}`;
      } else {
        return this.noImage.img;
      }
    },
    /**
     * 店舗毎のイメージファイル設定
     * 無ければnoImageを設定
     */
    noShopImage() {
      this.src = this.noImage.img;
    },
  },
};
</script>
<style scoped>
.v-window.v-item-group.theme--light.v-tabs-items {
  background-color: transparent;
}
footer {
  width: 100%;
  padding: 30px 0;
  position: absolute; /*←絶対位置*/
  bottom: 0; /*下に固定*/
}
div.map {
  position: relative;
}
div.slider-wrap {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  z-index: 5;
  position: absolute;
  /*左端を左寄せ*/
  left: 0%; 
  bottom: 0;
}

.fs4 {
  font-size: 1.3rem !important;
}

.card-title-style {
  /* background-image: url("~@/assets/nophoto.png"); */
  height: 100px;
}
.card-text-style {
  height: 70px;
  padding: 25px 0 0 0;
  text-align: center;
}
div.shopname {
  padding: 0;
  margin: 0;
}
div.address {
  padding: 0;
  margin: 0;
}
span.shop-name-font {
  font-size: 1rem;
  line-height: 0em;
}
span.addressfont {
  font-size: 0.6rem;
  line-height: 0em;
}
.card-btn-style {
  z-index: 250;
  padding: 15px;
}
.v-tab--active {
  color: #e2333e !important;
}
.v-tabs-slider {
  color: #e2333e !important;
}
.tabs-left {
  font-size: 0.75rem;
  background-color: #fafafa;
}
.tabs-center {
  font-size: 0.75rem;
  background-color: #fafafa;
}
.tabs-right {
  font-size: 0.75rem;
  background-color: #fafafa;
}
.follow_card {
  padding: 0 5px 5px 0;
}
.follow-card-text-style {
  height: 70px;
  padding: 0 0 0 0;
  text-align: center;
}

.v-expansion-panel:not(:first-child)::after {
  border: none;
}

.v-expansion-panel::before {
  box-shadow: none;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--active
  + .v-expansion-panel,
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)
  > .v-expansion-panel--next-active {
  border-radius: 0px;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 12px 16px 12px !important;
  box-sizing: border-box;
}

.selectwrap {
  margin-bottom: 20px;
  border-radius: 22px;
  border: none;
  background: #fff;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}
</style>
<style>
.v-slide-group__prev {
  display: none !important;
}

body {
  background-image: initial;
  background-color: #fff;
}
/*店舗検索 エリアから*/
.area-list {
  padding: 0px;
}
.area-block {
  display: -webkit-box;
  background-color: #fff;
  -webkit-transition: all .7s;
  transition: all .4s;
}
/* .area-block:hover{
  background-color: #CDCDCD;
  transition: background-color .4s;
}
.area-block:hover::before{
  background-color: #fff;
}
.area-block:hover::after{
  background-color: #fff;
} */
.area-block:active {
  background-color: #CDCDCD;
  transition: background-color .4s;
}
.area {
  font-size: 0.875rem;
  font-weight: bold;
}
.area-active {
  color: #333 !important;
}
.v-icon {
  color: #707070 !important;
  font-size: 0.75rem !important;
}
.shop_detail_icon {
  padding: 10px 15px 0 10px;
  width: 10%;
}
.shop_detail_icon > .v-icon {
  padding-top: 0.5rem;
  font-size: 1.25rem !important;
}
.area-list-item {
  /* padding-left: 2rem !important; */
  border-top: 1px solid #e6e6e6;
}
.area-list-item > .area-list-item-shop:active {
  background-color: #CDCDCD;
  transition: background-color .2s;
}
.area-list-item-shop {
  display: flex;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  background-color: #f7f7f7;
}
.shop_detail {
  line-height: 1.4 !important;
  width: 80%;
  margin-left: 2rem !important;
}
.category_name {
  font-size: 0.875rem;
  font-weight: bold;
  color: #333;
  margin-left: 2rem !important;
  min-height: 3rem;
  padding-top: 0.9rem;
}
.shop_category_name {
  font-size: 0.6125rem;
}
.shop_name {
  font-size: 0.875rem;
}
.address {
  font-size: 0.75rem;
  color: #707070;
}
.genre01 {
  display: inline-block;
  background: #eb7178;
  color: #fff;
  font-size: 0.5rem;
  padding: 0.175rem 0.45rem;
  margin: 0 0.25rem;
}
.genre02 {
  display: inline-block;
  background: #EC9F30;
  color: #fff;
  font-size: 0.5rem;
  padding: 0.175rem 0.45rem;
  margin: 0 0.25rem;
}
.v-card--link:focus:before {
  opacity: 0;
}
/*ICON FONT*/
@font-face {
  font-family: 'yamaya_icon';
  src: url('~@/assets/font/yamaya_icon.ttf') format('truetype'), url('~@/assets/font/yamaya_icon.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
[class^="i_"], [class*=" i_"] {
  font-family: 'yamaya_icon' !important;
  /*speak: none;*/
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.i_star:before {
  content: "\e903";
}
.i_arrow_r:before {
  content: "\e901";
}
.b_fav {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
  overflow: visible;
  max-width: 15rem;
  line-height: 1.5;
  color: #fff !important;
  position: relative;
  text-decoration: none;
  transition: all .2s;
  cursor: pointer;
  box-shadow: 0 1rem 0.5rem -1rem rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  border-radius: 2rem;
  color: #fff;
  width: 3rem;
  border: 2px solid #CDCDCD;
  padding: 0.1rem 0.7rem;
  background: #CDCDCD;
  margin: 0 0.25rem;
}
.b_fav .icon::before {
  font-size: 1.4rem;
}
.b_fav.on {
  border: 2px solid #EB7178;
  background: rgba(235, 113, 120, 0.15);
}
.b_fav.on .icon::before {
  color: #EB7178;
}
.row {
  margin: initial;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
	border-radius: 15px 15px 0 0 !important;
}
.v-tabs-items .v-slide-group__content {
  padding-left: 2rem;
  padding-right: 2rem;
}
.fav-item {
  padding: 0.5rem 0 0.75rem 0.75rem;
  border-bottom:1px solid #E6E6E6
}
.shop-detail-btn {
  border: 1px solid #808080;
  background-color: #fff !important;
  width: 5.25rem;
  height: 1.5rem !important;
  margin-right: 0.5rem;
  margin-bottom: 0.1rem;  
  font-weight: bold;
  font-size: 0.7rem !important;
  color: #808080 !important;
}
.arrow-r {
  font-size: 1.5rem;
}
.display-block {
  display: block;
}
.display-none {
  display: none;
}
.expand-transition-leave-active {
  transition: none !important;
}
.v-list-group__header__append-icon {
  display: none !important;
}
.area_icon > .v-icon {
  font-size: 1.25rem !important;
}
.v-list-item:hover::before {
  opacity: 0 !important;
}
.map-category-img {
width:3.75rem; height:3.75rem; margin:0 0.5rem 0.5rem 0;
}
.map-shop-name {
  width:calc(100% - 4.75rem); font-weight:bold; margin-bottom:0.5rem;
}
</style>
