<template>
<v-content>

</v-content>
</template>

<script>
export default {
  data() {
    return {
      init: null,
    };
  },
  async created() {
    this.init = this.storageGet("*");
    const moplink = "https://" + process.env.VUE_APP_HOST_NAME + "/mop/shop-list?sid="+this.init.sid+"&os="+this.init.os+"&device_id="+this.init.device_id+"&key="+this.init.key+"&registration_id="+this.init.registration_id+"&app_version="+this.init.app_version+"&feature_id=1&device_uid="+this.init.device_uid

    location.replace(moplink)
  }
}
</script>
