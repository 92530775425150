<template>
  <div class='warp_comment'>
    <TalkingShop :headerHeight="headerHeight" :talking_shop_name="selected_shop" :router_param="{ name: 'talk_shops', query: { feature_id: 'talk'}}"></TalkingShop>
    <UIDialogDelete
        ref="UIDialogDelete"
        :title="dialogTitle"
        :text="dialogText"
        :delete-btn-flg="true"
        @set-del-btn-change="setDelBtnChange"
    />
    <UIDialogDelete
        ref="UIDialogError"
        :title="dialogTitle"
        :text="dialogText"
        :delete-btn-flg="false"
    />
    <v-card class="pa-5 warp_comment_s" id="warp_comment" @touchmove="inputBlur">
      
      <div class="_list-container my-1">
        <template v-for="item in result">
          <v-row
            v-if="item.border_flg && !item.del_flg"
            :key="`border_${item.id}`"
            align="center"
            class="_date-border"
            no-gutters
          >
            <v-col>
              <v-divider />
            </v-col>
            <v-col class="shrink font-weight-bold pl-3 date-warp"
              >{{ item.create_date | dateFormat }}
            </v-col>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>
          <v-row
            v-if="!item.del_flg"
            :key="`comment_${item.id}`"
            no-gutters
            :class="commentClass(item)"
            class="box-height" 
          >
            <v-col v-if="item.admin_member_flg" class="pa-1">
                <img :src="item.image" alt="" class="_header_image" />
              <div v-if="item.content_type == 2 || item.content_type == 4" style="display: inline-block;">
                <!-- <viewer :options="options" :images="item.content" class="viewer">
                  <img 
                    :src="item.content" 
                    alt="添付画像" 
                    class="_image" 
                    style="border-radius: 9px 9px 9px 0px !important"
                  />
                </viewer> -->
              </div>
              <div v-else-if="item.content_type == 6">
                <video
                  v-if="!item.delete_flg"
                  :src="item.content"
                  :poster="item.image_content"
                  controls="controls"
                  height="150"
                  width="200"
                  controlslist="nodownload"
                  @loadstart="loadFile"
                  style="border-radius: 9px 9px 9px 0 !important;"
                ></video>
                <v-card v-if="item.delete_flg" style="box-shadow: none; border-radius: 9px 9px 9px 0 !important;">
                  <video
                      :src="item.content"
                      :poster="item.image_content"
                      height="150"
                      width="200"
                      style="border-radius: 9px 9px 9px 0 !important;"
                  ></video>
                  <v-overlay absolute opacity="0.7">
                    <div class="text-center">
                      <img class="img" src="@/assets/video_error.svg" width="50" alt="">
                      <p class="flgtext">動画は削除されました</p>
                    </div>
                  </v-overlay>
                </v-card>
              </div>
              <v-card
                v-else
                class="pa-2 body-2"
                style="display: inline-block; max-width: 70%; vertical-align: middle; border-radius: 9px 9px 9px 0 !important; text-align: left;"
              >
                <template v-if="item.content_type == 5">                
                  <a @click="toHref(item.content)">
                    <!-- <pdf :src="item.content|urlFormat" class="pdf"> </pdf> -->
                  </a>
                </template>
                <template v-else>
                  <!-- 20210126 NEW_DEV-743 cyber 李 start -->
                  <a v-if="emailRegex(item.content)" :href="'mailto:' + item.content">{{item.content}}</a>
                  <!-- NEW_DEV-1505 cyber start -->
                  <span v-else v-html="userDetail(item.content, item.content_type)" style="white-space: pre-wrap !important;" @click="userDetailAlert"></span>
                  <!-- NEW_DEV-1505 cyber end -->
                  <!-- 20210126 NEW_DEV-743 cyber 李 end -->
                </template>
              </v-card>
              <div
                class="px-2 caption"
                style="display: inline-block; vertical-align: bottom; position: relative; top:5px"
              >
                <div></div>
                <div>{{ item.create_date | timeFormat }}</div>
              </div>
            </v-col>
            <v-col v-else class="pa-1 text-right" ref="userContentsBox" >
              <div
                v-show="isShowDeleteBtn"
                class="px-2 cancel-a"
              >
                <v-icon class="del-bg" @click="doDeleteComment(item)"
                  >cancel
                </v-icon>
              </div>
              <div
                class="px-2 caption"
                style="display: inline-block; vertical-align: bottom; position: relative; top:5px"
              >
                <div><span v-if="item.read_flg">既読</span></div>
                <div>{{ item.create_date | timeFormat }}</div>
              </div>
              <div v-if="item.content_type == 2" style="display: inline-block;">
                <!-- <viewer :options="options" :images="item.content" class="viewer">
                  <img 
                    :src="item.content" 
                    alt="添付画像" 
                    class="_image" 
                    @load='loadFile'
                    style="border-radius: 9px 9px 0px 9px !important;"
                  />
                </viewer> -->
              </div>
              <div v-else-if="item.content_type == 6" style="display: inline-block;">
                <video
                  v-if="!item.delete_flg"
                  :src="item.content"
                  :poster="item.image_content"
                  controls="controls"
                  height="150"
                  width="200"
                  controlslist="nodownload"
                  @loadstart="loadFile"
                  style="border-radius: 9px 9px 0 9px !important;"
                ></video>
                <v-card v-if="item.delete_flg" style="box-shadow: none; border-radius: 9px 9px 0 9px !important;">
                  <video
                      :src="item.content"
                      :poster="item.image_content"
                      height="150"
                      width="200"
                      style="border-radius: 9px 9px 0 9px !important;"
                  ></video>
                  <v-overlay absolute opacity="0.7">
                    <div class="text-center">
                      <img class="img" src="@/assets/video_error.svg" width="50" alt="">
                      <p class="flgtext">動画は削除されました</p>
                    </div>
                  </v-overlay>
                </v-card>
              </div>
              <v-card
                v-else
                color="primary"
                class="pa-2 body-2 white--text"
                style="display: inline-block; max-width: 70%; vertical-align: middle; border-radius: 9px 9px 0 9px !important; background-color: #FFE4F6 !important; color: black !important; text-align: left;"
              >
                <template v-if="item.content_type == 5">
                  <a @click="toHref(item.content)">
                    <!-- <pdf :src="item.content|urlFormat" class="pdf"> </pdf> -->
                  </a>
                </template>
                <template v-else>
                  <!-- 20210126 NEW_DEV-743 cyber 李 start -->
                  <a v-if="emailRegex(item.content)" :href="'mailto:' + item.content">{{item.content}}</a>
                  <!-- NEW_DEV-1505 cyber start -->
                  <span v-else v-html="userDetail(item.content)" style="white-space: pre-wrap !important;"></span>
                  <!-- NEW_DEV-1505 cyber end -->
                  <!-- 20210126 NEW_DEV-743 cyber 李 end -->
                </template>
              </v-card>
            </v-col>
          </v-row>
        </template>
      </div>
      <v-row no-gutters align="center" class="input_group">
        <v-col class="shrink trash-pos">
          <v-icon :class="(isShowDeleteBtn === true) ? 'trash-css' : ''" @click="commentDeleteCallback"
            >fa-trash</v-icon
          >
        </v-col>
        <v-col class="shrink paperclip-pos">
          <v-icon  @click="onFileChange"
            >fa-paperclip</v-icon
          >
        </v-col>
        <v-col class="input-box">
          <div id="pre" v-html="postCommentCopy"></div>
          <v-textarea
            v-model="postComment"
            class="px-2 text-s"
            outlined
            dense
            hide-details
            ref="textarea"
            @focus='toUserDetail'
            @keydown="scrollBottom"
            :maxlength="textMaxlength"
            :rows="rows_num"
          ></v-textarea>
        </v-col>
        <v-col class="shrink">
          <!-- 20210126 NEW_DEV-743 cyber 李 start -->
          <v-btn :disabled="post_btn_disabled" rounded color="primary btn-color" class="post-btn" :style="`height:${post_btn_height}`" @click.prevent="onPostComment"
            >送信</v-btn
          >
          <!-- 20210126 NEW_DEV-743 cyber 李 end -->
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
import TalkingShop from '@/components/talking_shop.vue';
import UIDialogDelete from '@/components/UIDialogDelete.vue';
import {getScrollTop,getScrollHeight,getWindowHeight} from "@/utils/talk-load.js";
// import pdf from 'vue-pdf'
import 'viewerjs/dist/viewer.css'
// import Viewer from "v-viewer/src/component.vue"
import emojiRegex from 'emoji-regex';
const UNREAD_CLASS = "unread";
export default {
  components: {
    TalkingShop,
    UIDialogDelete,
    // pdf,
    // Viewer
  },
  data: () => ({
      result: null,
      shop_id: 0,
      loading: false,
      postComment: '',
      isShowDeleteBtn: false,
      talking_shop: '',
      load_wait: true,
      scroll_top: 0,
      old_scroll_height: 0,
      isDelBtnChange: false,
      deletingCommentId: '',
      deletingCommentName: '',
      dialogTitle: '',
      dialogText: '',
      deleteCommentFlg: true,
      new_data: false,
      old_data: false,
      new_success_flg: true,
      failed: 0,
      post_data: false,
      timer: null,
      height: '36px',
      rows_num: 1,
      post_btn_height: '40px !important',
      badgeParams: {},
      userData: null,
      options: {'navbar': false, 'toolbar': false},
      textMaxlength: 10000,
      headerHeight: 'top:0px',
      post_btn_disabled: false,
      postCommentCopy: '',
      imgLoad: false,
      firstLoad: true,
  }),
  watch: {
    postComment: function() {
      if(this.postComment){
        this.postCommentCopy = this.postComment.replace(/\n/g, '<br/>');
      }
      this.setTextareaHeight();
      this.scrollBottom();
    },
  },
  mounted() {
    if(this.getUrlGetParam('preview') != 1){
      window.addEventListener('scroll',this.handleScroll,true);
    }
  },
  updated() {
    if(this.isDelBtnChange == false && this.deleteCommentFlg){ 
      if(getScrollTop() == 0 && this.load_wait){
        if(this.scroll_top != 0){
          this.scroll_top = getScrollHeight() - this.old_scroll_height;
          this.old_scroll_height = getScrollHeight();
        }else{
          this.old_scroll_height = getScrollHeight();
          this.scroll_top = getScrollHeight() - getWindowHeight();
        }
      
        this.scrollToHeight();
      }
    }
    if(this.post_data) {
      this.old_scroll_height = getScrollHeight();
      this.scroll_top = getScrollHeight() - getWindowHeight();
      this.scrollToHeight();
      this.post_data = false;
    }
    this.deleteCommentFlg = true;
    this.isDelBtnChange = false;
  },
  beforeRouteLeave(to, from, next){
    next();
    clearTimeout(this.timer);
    this.timer = null;
  },
  methods: {
    /**
     * メッセージを送る
     */
    async onPostComment() {
      // 20210127 NEW_DEV-743 cyber 李 start
      if (!this.postComment.trim()) return;
      this.post_btn_disabled = true;
      const regex = emojiRegex();
      let match = regex.exec(this.postComment);
      if(match) {
        this.dialogTitle = 'エラー';
        this.dialogText = '投稿に失敗しました。';
        this.$refs.UIDialogError.open()
        this.post_btn_disabled = false;
        return;
      }
      // 20210127 NEW_DEV-743 cyber 李 end
      // 20210121 NEW_DEV-743 cyber 李 start
      var request = { 
          shop_id: this.shop_id,
          id: this.result[0].talk_id,
          //NEW_DEV-1505 cyber start
          value: this.postComment,
          //NEW_DEV-1505 cyber end
          type: 1,
      };
      // 20210121 NEW_DEV-743 cyber 李 end
      var post_comment_res = await this.apiCall('/talk/comment', request, 'post');
      if (post_comment_res) {
        let res = this.result;
        res.push.apply(res, post_comment_res.data);
        res.sort(function(x, y){
          return x.id - y.id;
        });
        this.$set(this, 'result', res);
        this.postComment = null;
        this.post_data = true;
        if(post_comment_res.data[0].is_mail == 1) {
          this.sendEmail(post_comment_res.data[0].id);
        }
      }
      
      // 20210126 NEW_DEV-743 cyber 李 start
      this.post_btn_disabled = false;
      // 20210126 NEW_DEV-743 cyber 李 end
    },
    /**
     * 既読判定
     */
    commentClass(item) {
      if (item.isRead) {
        return "";
      } else {
        return UNREAD_CLASS;
      }
    },
    /**
     * ドキュメントの高さを取得する
     */
    scrollToHeight() {
      this.$nextTick(() => {
        document.documentElement.scrollTop = this.scroll_top;
      });
    },
    /**
     * 履歴コメント取得する
     */
    handleScroll() {
      if(getScrollTop() == 0 && this.load_wait){
        this.firstLoad = false;
        this.old_data = true;
        this.load_wait = false;
        this.getCommentList();
      }
    },
    /**
     * コメント取得する
     */
    async getCommentList(){
      
      let talk_comment_request = { params:{
        'shop_id': this.shop_id,
        'preview':  this.getUrlGetParam('preview'),
        'sid': this.getUrlGetParam('sid')
      }}
      if(this.old_data) {
        talk_comment_request.params.min_id = this.result[0].id;
        talk_comment_request.params.old_data = true;
      }else if(this.new_data) {
        talk_comment_request.params.min_id = this.result[0].id;
        talk_comment_request.params.new_data = true;
      }
      let res = await this.apiCall('/talk/timeline', talk_comment_request);
      if (res.data.length == 0) return false;
      if(this.old_data) {
        let old_data = this.result;
        res.data.push.apply(res.data, old_data);
        this.$set(this, 'result', res.data);
        this.old_data = false;
      }else if(this.new_data) {
        let old_length = this.result.length;
        this.$set(this, 'result', res.data);
        let new_length = this.result.length;
        if(new_length > old_length) {
          this.scrollBottom();
        }
        this.new_data = false;
        if (!res.data) this.new_success_flg = false;  
      }else {
        this.$set(this, 'result', res.data);
      }

      if (!res) return false;
      if (!res.data) return false;  
      this.load_wait = true;
      this.new_success_flg = true;
      // 20210114 NEW_DEV-743 cyber 李 start
      this.badgeParams['_vue_param'] = true;
      this.badgeParams['menu_data'] = res.menu_badges.menu_data;
      this.badgeParams['total_unread_count'] = res.menu_badges.total_unread_count;
      this.nativePost(this.badgeParams);
      // 20210114 NEW_DEV-743 cyber 李 end
    },
    /**
     * コメント削除ボタンが表示/非表示
     */
    setDelBtnChange() {
      this.isShowDeleteBtn = true;
    },
    /**
     * コメント削除ボタンが押下された際のコールバック
     */
    async commentDeleteCallback() {
      if (!this.isShowDeleteBtn) {
        this.dialogTitle = '投稿した内容を削除しますか？';
        this.dialogText = '投稿したコメント・画像・動画を個別に削除することができます。\n削除した内容は元に戻せません。\n相手側の端末からは削除されません。';
        this.$refs.UIDialogDelete.open();
        this.isDelBtnChange = true;
      } else {
        this.isShowDeleteBtn = false;
      }
    },
    /**
     * コメント削除ダイアログの削除のコールバック
     */
    async doDeleteComment(item) {
      this.deletingCommentId = item.id
      if (!this.deletingCommentId) {
        return;
      }
      let request = {
        'id': this.deletingCommentId,
        'shop_id': this.shop_id,
      }
      let res = await this.apiCall('/talk/comment/delete', request, 'post');
      let j = 0;
      if(res.status == 100){
        this.deleteCommentFlg = false;
        let data_list = [];
        for(let i in this.result) {
          if(this.result[i].id != this.deletingCommentId){
            data_list[j] = this.result[i];
            j++;
          }
        }
        this.$set(this, 'result', data_list);
      }
    },
    /**
     * ファイル送信
     */
    async onFileChange() {
      let request = { params:{}};
      this.userData = await this.apiCall('/user/info', request);
      if(this.userData.name == '' || this.userData.name == null || this.userData.name == undefined) {
        this.userToNative();
      }else{
        this.fileToNative();
      }    
      
    },
    /**
     * PDF判定
     */
    isPDFExtension(file) {
      let pos = file.name.lastIndexOf(".");
      if (pos === -1) return false;
      return file.name.slice(pos + 1).toLowerCase() === "pdf";
    },
    /**
     * 動画判定
     */
    isMovie(file) {
      let pos = file.name.lastIndexOf(".");
      if (pos === -1) return false;
      if(file.name.slice(pos + 1).toLowerCase() === "mp4" || file.name.slice(pos + 1).toLowerCase() === "mov" || file.name.slice(pos + 1).toLowerCase() === "rmvb" || file.name.slice(pos + 1).toLowerCase() === "rm"){
        return true;
      }else{
        return false;
      }
    },
    /**
     * ポーリング
     */
    loopsiloop(interval) {
      this.timer = setTimeout(() => {
        this.new_data = true;
        this.getCommentList();
        if(this.new_success_flg){
          interval = 60000;
          this.loopsiloop(interval);
        }else{
          if(++this.failed < 10){
            interval = interval + 1000;
            this.loopsiloop( interval );
          }
        }
      }, interval);
    },
    setTextareaHeight(){
      let pre = document.getElementById('pre');
      if(pre.clientHeight/24 >= 8) {
        this.rows_num = 8;
        this.post_btn_height = '230px !important';
      }else if(pre.clientHeight/24 <= 0) {
        this.rows_num = 1;
        this.post_btn_height = '40px !important';
      }else {
        this.rows_num = pre.clientHeight/24;
        if(this.rows_num < 2) {
          this.post_btn_height = '40px !important';
        }else if(this.rows_num == 2) {
          this.post_btn_height = '62px !important';
        }else if(this.rows_num > 2) {
          this.post_btn_height = 62 + (this.rows_num - 2) * 28 +'px !important';
        }
      }
      if(this.postComment == null){
        this.post_btn_height = '40px !important';
        this.rows_num = 1;
      }
    },
    scrollBottom() {
      this.old_scroll_height = getScrollHeight();
      this.scroll_top = getScrollHeight() - getWindowHeight() + 500;
      this.scrollToHeight();
    },
    async toUserDetail() {
      let request = { params:{}};
      this.textMaxlength = 0;
      this.userData = await this.apiCall('/user/info', request);
      if(this.userData.name == '' || this.userData.name == null || this.userData.name == undefined) {
        this.$refs.textarea.blur();
        this.userToNative();
      }else{
        this.textMaxlength = 10000;
        this.scrollBottom();
      }  
    },
    async uploadComplete(res = null){
      let u = navigator.userAgent;
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
      if(res) {
        if(isAndroid) {
          res = JSON.parse(res);
        }
        if(res.data[0].is_mail == 1 && res.data[0].content_type == 2) {
          this.sendEmail(res.data[0].id);
        }
      }
      let talk_comment_request = { params:{
        'shop_id': this.shop_id,
      }}
      let talk_comment_res = await this.apiCall('/talk/timeline', talk_comment_request);
      this.imgLoad = true;
      this.$set(this, 'result', talk_comment_res.data);
    },
    /**
     * ユーザー情報のリンクを調整する
     */
    userDetail(content, type) {
      if(type == 3){
        if(content != null && content != undefined && content != ''){
          return content.replace('ユーザー情報', "<a href='javascript:void(0)' class='user_detail'>ユーザー情報</a>");
        }
      //NEW_DEV-1505 cyber start
      }else if(/<\/?[a-z][\s\S]*>/.exec(content)){
        return content;
      //NEW_DEV-1505 cyber end
      }else{
        let preg = /(?:(?:https?|ftp|file):\/\/|[-A-Za-z]*\.|ftp\.)(?:\([-A-Za-z0-9+&@#/%=~_|$?!:,.]*\)|[-A-Za-z0-9+&@#/%=~_|$?!:,.])*(?:\([-A-Za-z0-9+&@#/%=~_|$?!:,.]*\)|[A-Za-z0-9+&@#/%=~_|$])/g;
        return content.replace(/&nbsp;/g, ' ').replace(/&nbsp/g, ' ').replace(preg, function(a){
          if(!a.indexOf('http')){
            return '<a href="'+a+'" target=_blank>'+a+'</a>';
          }
          else
          {
            return '<a href="https://'+a+'" target=_blank>'+a+'</a>';
          }
        });
      }  
      return content;
    },
    userDetailAlert(event) {
      if (event.target.nodeName === 'A' && event.target.className === 'user_detail') {
        this.userToNative();
      }    
    },
    userToNative() {
      let params = {
          '_vue_param': true,
          'is_talk': true,
          'user_detail_flg': 1,
      };
      alert(JSON.stringify(params));
    },
    fileToNative() {
      let params = {
            '_vue_param': true,
            'is_talk': true,
            'file_upload_flg': 1,
            'id': this.result[0].talk_id,
            'shop_id': this.shop_id,
            'talk_movie_flg': this.init.talk_movie_flg
          };
      alert(JSON.stringify(params));
    },
    inputBlur() {
      this.$refs.textarea.blur();
    },
    hearderHeightFix(height) {
      this.headerHeight = 'top: ' + (height * 0.9) + 'px';
    },
    // 20210126 NEW_DEV-743 cyber 李 start
    toHref(url) {
      if(url.startsWith("www.") && !/:/.exec(url)){
        url = "https://" + url;
      }
      const req = {
        destination: encodeURI(url),
        transition_type: 5,
        'is_talk': true,
      };
      if (!(this.screenTransition(req))) {
        this.callDialog('遷移エラー', '遷移情報が不正です。再度アクセスして下さい。', 2);
      }
    },
    emailRegex(email) {
      // eslint-disable-next-line no-control-regex
      const regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
      return regex.exec(email);
    },
    urlRegex(url) {
      const regex = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www\.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w\-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[.!/\\\w]*))?)/;
      return regex.exec(url);
    },
    // 20210126 NEW_DEV-743 cyber 李 end
    loadFile() {
      if(this.imgLoad || this.firstLoad) {
        this.scrollBottom();
        this.imgLoad = false;
      }  
    },
    async sendEmail(id) {
      let request = {
        comment_id:id,
      }
      await this.apiCall('/talk/send-email', request, 'post');
    }
  },
  filters: {
    /**
     * 日付形式を調整する
     */
    dateFormat(date) {
      let dateArr = date.split(" ")[0].split("-");
      let nowDate = new Date();
      if(dateArr[0] != nowDate .getFullYear()){
        return dateArr[0] + '年' + dateArr[1] + '月' + dateArr[2] + '日';
      }else{
        let d = new Date(date.replace(/-/g, '/'));
        let wDay = ["日", "月", "火", "水", "木", "金", "土"][d.getDay()];
        return dateArr[1] + '月' + dateArr[2] + '日' + '（' + wDay + '）';
      }
      
    },
     /**
     * 日付形式を調整する
     */
    timeFormat(date) {
      let dateArr = date.split(" ")[1].split(":");
      return dateArr[0] + ':' + dateArr[1] ;
    },
    /**
     * pdfUrlを調整する
     */
    urlFormat(url) {
      let host = location.host;
      let protocol = location.protocol;
      let url_arr = url.split('/');
      url_arr.splice(0,3)
      url = url_arr.join('/');
      return protocol + '//' + host +'/' +  url;
    },
    /**
     * pdfNameを調整する
     */
    nameFormat(url) {
      let url_arr = url.split('/');

      return url_arr[url_arr.length-1];
    },
  },
  async created() {
    this.init = this.storageGet('*');
    this.selected_shop = this.getUrlGetParam('selected_shop');
    this.shop_id = this.getUrlGetParam('shop_id');
    this.getCommentList();
    if(this.getUrlGetParam('preview')!=1){
      this.loopsiloop(60000);
      let params = {
              '_vue_param': true,
              'is_talk': true,
              'talk_init_flg': 1,
            };
      alert(JSON.stringify(params));
      let request = { params:{}};
      this.userData = await this.apiCall('/user/info', request);
    }
    window.uploadComplete = this.uploadComplete;
    window.hearderHeightFix = this.hearderHeightFix;
  }
}
</script>

<style scoped>
/* vue css override */

.warp_comment {
  height: 100%;
}
.warp_comment_s {
  height: 100%;
  padding: 0 10px !important;
  margin-top: 48px !important;
}
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 0px !important;
}
.input_group {
  position: fixed;
  bottom:20px;
  right: 20px;
}
.input-box {
  width: 200px;
}
.btn-color {
  background-color: #ff00b1 !important;
}
.v-btn--rounded {
  border-radius: 10px;
}
.v-icon{
  line-height: 40px;
  color: white;
  background: darkgrey;
  border-radius: 8px;
  width: 40px;
  height: 40px;
}
.del-bg {
  background: transparent;
  color: #C4C4C4 !important;
  height:30px;
}
.date-warp {
  flex-grow: 1.2 !important;
  border: 0.5px #F9F9F9 solid;
  border-radius: 12px;
  text-align: center;
  font-weight: normal !important;
  font-size: 11px;
  line-height: 15px;
  padding: 4px 0px !important;
  color: #C8C7C7;
  box-shadow: 0 0 10px #E2E2E2;
}
.box-height{
  padding: 14px 0;
}
._image {
  max-width: 200px;
  max-height: 200px;
}

._header_image{
  width: 30px;
  height: 30px;
  border-radius: 15px;
  vertical-align: bottom;
  margin-right: 6px;
}
.pdf {
  width: 200px;
  height: 150px;
  display: inline-block;
  overflow: scroll;
}
#pre {
  width:170px;
  /* border: black 1px solid; */
  position: absolute;
  left: -1000px;
}
.post-btn {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  min-width: 56px !important;
  width: 56px;
  position: relative;
  top: -1px;
}
.trash-pos {
  position: fixed;
  bottom: 21px;
  left: 20px;
}
.trash-css {
  border: 2px solid #ff00b1 !important;
  background: white !important;
  color: #ff00b1 !important;
}

.paperclip-pos {
  position: fixed;
  bottom: 21px;
  left: 62px;
}
.cancel-a {
  display: inline-block; 
  vertical-align: top; 
  position: relative; 
  bottom:14px; 
  left: 60px;
}
.cancel-b {
  display: inline-block; 
  vertical-align: top; 
  position: relative; 
  bottom:14px; 
  left: 30px;
}
</style>

<style> 
  #app {
    height: 100%;
  }
  .text-s > .v-input__control > .v-input__slot{
    width: 194px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    background-color: white;
  }
  .viewer-open {
    padding: 0 !important;
  }
</style>
