<template>
  <v-main class="ticket_complete">

    <div class="px-3 mt-12">
      <div class="py-10 text-center">
        <svg class="svg_maincolor" xmlns="http://www.w3.org/2000/svg" width="90" height="82.733" viewBox="0 0 90 82.733"><path d="M345.15,1835.711v31.813H280.271v-64.335h64.879v6.526l9.271-9.19v-6.535H271v82.733h83.417v-50.2Zm9.3-28.714L318.4,1842.738l-19.661-19.5-6.554,6.5,26.215,26L361,1813.5Z" transform="translate(-271 -1793.991)" fill="#ff00b1" fill-rule="evenodd"/></svg>
        <p class="mt-5 title" style="font-family: 'Oswald', sans-serif !important;">Complete</p>
      </div>

      <div class="mt-4 px-4 text-center">
        <h1 class="body-1 font-weight-bold mb-10">チケット利用が完了しました</h1>
      </div>
    </div>

  </v-main>
</template>

<script>
export default {
  props: [],
  data() {
    return {
    };
  },
}
</script>

<style scoped>
</style>
