<template>
    <v-row no-gutters :style="headerHeight">
        <v-col cols="12" class="">
            <v-toolbar class="elevation-1" dense>
                <div class="selectedtext">現在トーク中の<br>店舗</div>
                <v-spacer></v-spacer>
                <v-toolbar-title class="selectedshopname">{{talking_shop_name}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="routerBindTo(router_param)">
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </v-toolbar>
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        talking_shop_name: {
            type: String,
            required: true
        },
        router_param: {
            type: Object,
            required: true
        },
        headerHeight: {
            type: String,
            required: true
        }
    }
}
</script>

<style scoped>
.selectedtext {
    color: #9D9D9D;
    border: solid 1px #9D9D9D;
    border-radius: 30px;
    font-size: 10px;
    padding: 5px 8px;
    line-height: 1.1;
    text-align: center;
    transform: scale(0.9);
    transform-origin: left center;
}

.selectedshopname {
    font-weight: bold;
    font-size: 11px;
    text-align: left !important;
    color: #333;
}
.row {
    position: fixed;
    width: 100%;
    z-index: 100;
}
</style>
