<template>
    <v-content class="mypage">

    </v-content>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      init: [],
    };
  },
  methods: {
  },
};
</script>

<style scoped>

</style>
