<template>
    <v-content v-if="result">
      <UIDialogDeleteRecent
        ref="UIDialogDeleteRecent"
        @delete-recent-shop="deleteRecentShop"
      />
      <!-- 最近予約した店舗 -->
      <div v-if="recent_flg">
        <p class="shopselecttitle mb-0">{{historytext}}</p>

        <div class="historywrap" v-for="(history, index) in result.recent_shop" :key="index">
          <div class="historyshops" @click.stop="toRouterPush(result.screen_id, history.id, history.name)">
            <div class="tex"><v-icon class="del-bg" @click.stop="TalkDeleteCallback(history.talk_id, index)">cancel</v-icon>{{history.name}}</div>
            <div class="icon"><v-chip
              class="px-2 red"
              small
              v-show="history.unread_count > 0"
              ><span class="white--text">{{
                history.unread_count
              }}</span></v-chip
            ><v-icon color="#9D9D9D">mdi-chevron-right</v-icon></div>
          </div>
        </div>
        <!-- <div v-else="result.recent_shop" class="fontsize13 text-center py-6">
          最近予約した店舗はありません。
        </div> -->
      </div>

        <p class="shopselecttitle">{{headtext}}</p>

        <div class="px-3 exmenu">
        <v-expansion-panels :flat="flat" v-model="panel[item.id]" :disabled="disabled" v-for="item in result.shop_list" :key="item.id" class="panelwrap">
            <v-expansion-panel class="selectwrap">
                <v-expansion-panel-header class="selecthead px-4">
                    {{item.name}}
                    <template v-slot:actions><v-icon color="#9D9D9D">mdi-chevron-down</v-icon></template>
                </v-expansion-panel-header>

                <v-expansion-panel-content v-for="shopOrCategories in item.list" :key="shopOrCategories.id" class="">

                    <div v-if="shopOrCategories.list == null" class="body-2 shopname_wrap">
                      <v-btn class="px-0" block text @click.stop="toRouterPush(result.screen_id, shopOrCategories.id, shopOrCategories.name)">
                        <v-row>
                          <v-col cols="10" class="shopname">
                            {{shopOrCategories.name}}
                          </v-col>
                          <v-col cols="2">
                            <v-icon color="#9D9D9D">mdi-chevron-right</v-icon>
                          </v-col>
                        </v-row>
                      </v-btn>
                      <span v-if="shop_follow_flg" class="follow_stars">
                        <transition enter-active-class="animate__animated animate__bounceIn">
                          <v-icon small color="#EED500" class="stars" v-show="shopOrCategories.follow_status == 1" @click.stop="followSet(0, shopOrCategories.name, shopOrCategories.id, shopOrCategories)">fas fa-star</v-icon>
                        </transition>
                        <transition enter-active-class="animate__animated animate__bounceIn">
                          <v-icon small color="#9D9D9D" class="stars" v-show="shopOrCategories.follow_status == 0" @click.stop="followSet(1, shopOrCategories.name, shopOrCategories.id, shopOrCategories)">fas fa-star</v-icon>
                        </transition>
                      </span>
                    </div>
                    <div v-if="shopOrCategories.list != null">
                        <v-expansion-panels :flat="flat">
                        <v-expansion-panel class="">
                            <v-expansion-panel-header class="selecthead px-2 body-2">
                                {{shopOrCategories.name}}
                                <template v-slot:actions><v-icon color="#9D9D9D">mdi-chevron-down</v-icon></template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content v-for="shop in shopOrCategories.list" :key="shop.id" class="px-0 fontsize13">
                              <div class="body-2 shopname_wrap">
                                  <v-btn class="px-0" block text @click.stop="toRouterPush(result.screen_id, shop.id, shop.name)">
                                    <v-row>
                                      <v-col cols="10">
                                        {{shop.name}}
                                      </v-col>
                                      <v-col cols="2">
                                        <v-icon color="#9D9D9D">mdi-chevron-right</v-icon>
                                      </v-col>
                                    </v-row>
                                  </v-btn>
                                  <span v-if="shop_follow_flg" class="follow_stars">
                                    <transition enter-active-class="animate__animated animate__bounceIn">
                                      <v-icon small color="#EED500" class="stars" v-show="shop.follow_status == 1" @click.stop="followSet(0, shop.name, shop.id, shop)">fas fa-star</v-icon>
                                    </transition>
                                    <transition enter-active-class="animate__animated animate__bounceIn">
                                      <v-icon small color="#9D9D9D" class="stars" v-show="shop.follow_status == 0" @click.stop="followSet(1, shop.name, shop.id, shop)">fas fa-star</v-icon>
                                    </transition>
                                  </span>
                              </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        </v-expansion-panels>
                    </div>

                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
        </div>

    <v-overlay :value="loading" z-index=9999>
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>

    </v-content>
</template>

<script>
import UIDialogDeleteRecent from '@/components/UIDialogDeleteRecent.vue';
export default {
  components: {
    UIDialogDeleteRecent,
  },
  data: () => ({
      result: null,
      historytext: '最近トークした店舗',
      headtext: 'トークをしたい店舗を選択する',
      feature_id: 'talk',
      disabled: false,
      panel: [],
      allshop_flg: 1,
      booking_history_flg: false,
      shop_follow_flg: false,
      flat: true,
      loading: false,
      recent_flg : false,
      badgeParams: {},
      recent_index: null,
  }),
  methods: {
    async followSet(flg, name, shop_id, shop){
      shop.follow_status = flg
      try {
        //this.loading = true
        /* 店舗フォローAPIを叩く */
        var req = { 
          'shop_id': shop_id,
          'flg': flg,
        }
        var follow_res = await this.apiCall('/user/favorite', req, 'post');
        if (!follow_res) return false;

        if(flg == 1){
          this.result.recommend_shop_list.push({ name: name, id: shop_id })
        }else{
          const index = this.result.recommend_shop_list.findIndex((v) => v.id === shop_id);
          this.result.recommend_shop_list.splice(index, 1);
        }

        shop.follow_status = flg

        this.loading = false
      } catch(e) {
        this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
        this.loading = false
      }
    },
    toRouterPush(screen_id, shop_id, selected_shop){
      this.$router.push({ name: 'talk', query: { shop_id: shop_id, selected_shop: selected_shop}})
    },
    async deleteRecentShop(){
      let request = {
        'id': this.deletingTalkId,
        'flg': 0,
      }
      let res = await this.apiCall('/talk/delete-recent', request, 'post');
      if(res.status == 100) {
        this.result.recent_shop.splice(this.recent_index,1);
        if(this.result.recent_shop.length == 0){
          this.recent_flg = false;
        }
      }
    },
    /**
     * コメント削除ボタンが押下された際のコールバック
     */
    async TalkDeleteCallback(id, index) {
      this.$refs.UIDialogDeleteRecent.open()
      this.deletingTalkId = id;
      this.recent_index = index;
    },
  },
  async created() {
    this.getUrlParam()
    this.init = this.storageGet('*')

    var shoplist_req = { params:{
      'sid': this.init.sid,
      'device_uid': this.init.device_uid,
      'device_id': this.init.device_id,
      'os': this.init.os,
      'key': this.init.key,
      'location': 0,
      'feature_id': this.feature_id,
    }}

    var res = await this.apiCall('/user/shops', shoplist_req);

    var recent_shoplist_req = { params:{
      'sid': this.init.sid,
      'device_uid': this.init.device_uid,
      'device_id': this.init.device_id,
      'os': this.init.os,
      'key': this.init.key,
    }}
    var recent_shop = await this.apiCall('/talk/recent', recent_shoplist_req, 'post');
    if(recent_shop.data.length != 0){
      this.recent_flg = true;
    }
    res.recent_shop = recent_shop.data;
    this.$set(this, 'result', res)
    if (!res) return false;
    // 20210114 NEW_DEV-743 cyber 李 start
    this.badgeParams['_vue_param'] = true;
    this.badgeParams['menu_data'] = recent_shop.menu_badges.menu_data;
    this.badgeParams['total_unread_count'] = recent_shop.menu_badges.total_unread_count;
    this.nativePost(this.badgeParams);
    // 20210114 NEW_DEV-743 cyber 李 end
  }

}
</script>

<style scoped>
/* vue css override */

.v-expansion-panel:not(:first-child)::after {
    border: none;
}

.v-expansion-panel::before {
    box-shadow: none;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile) > .v-expansion-panel--active {
    border-radius: 22px;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 12px 16px 12px !important;
  box-sizing: border-box;
}

/* original css */
.shopselecttitle {
    margin: 0 0 15px 0;
    width: 100%;
    padding: 5px 12px;
    background-color: #E4E4E4;
    color: #9D9D9D;
    font-size: 14px;
}
.selectwrap {
    margin-bottom: 20px;
    border-radius: 22px;
    border: none;
    background: #fff;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
}

.selecthead {
    font-weight: bold;
    font-size: 14px;
    color: #9D9D9D;
}

.allshops {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: #9D9D9D;
  border-radius: 24px;
  font-size:14px;
  line-height: 14px;
  font-weight: bold;
  padding: 12px 24px;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

.historyshops {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: #9D9D9D;
  font-size:14px;
  line-height: 14px;
  font-weight: bold;
  padding: 12px 24px;
  box-sizing: border-box;
  margin-bottom: 0;
  position: relative;
}
.historyshops a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}
.historywrap {
  border-bottom: solid 1px #DEDEDF;
}
.historywrap:last-of-type {
  border-bottom: none;
}
.shopname {
  text-align: left;
  padding-left: 14px;
  line-height: 1.9;
}
.shopname_wrap {
  position: relative;
}
.follow_stars {
  display: block;
  position: absolute;
  top: 6px;
  right: 28px;
  width: 20px;
  height: 20px;
  overflow: hidden;
}
a {
    text-decoration: none !important;
    color: #333333;
    font-weight: bold;
}

.exmenu >>> .v-btn:before {
  background-color:#fff;
}
.del-bg {
  background: white;
  color: #C4C4C4 !important;
  height:30px;
  position: relative;
  left:-14px;
}
</style>
