<template>
  <v-content v-if="result">
    <!-- 最近予約した店舗 -->
    <div v-if="booking_history_flg">
      <p class="shop_select_title mb-0">{{history_text}}</p>

      <div v-if="result.booking_history">
        <div class="history_wrap" v-for="(history, index) in result.booking_history" :key="index">
          <div class="history_shops">
            <div class="tex">{{history.name}}</div>
            <div class="icon"><v-icon color="#9D9D9D">mdi-chevron-right</v-icon></div>
            <router-link v-bind:to="{ name: result.screen_id, query: { shop_id: history.id, selected_shop: history.name}}"></router-link>
          </div>
        </div>
        </div>
      <div v-else class="fontsize13 text-center py-6">
        最近予約した店舗はありません。
      </div>
    </div>

    <!-- 最近購入した店舗 -->
    <div v-if="result.digital_ticket_history && digital_ticket_history_flg">
      <p class="shop_select_title mb-0">{{history_text}}</p>

      <div class="history_wrap" v-for="(history, index) in result.digital_ticket_history" :key="index">
        <div class="history_shops">
          <div class="tex">{{history.name}}</div>
          <div class="icon"><v-icon color="#9D9D9D">mdi-chevron-right</v-icon></div>
          <router-link v-bind:to="{ name: result.screen_id, query: { shop_id: history.shop_id, selected_shop: history.name}}"></router-link>
        </div>
      </div>
    </div>

    <!-- フォローしている店舗 -->
    <div v-if="shop_follow_flg">
      <p class="shop_select_title mb-0">{{followtitle}}</p>

      <div v-if="result.recommend_shop_list">
        <div class="history_wrap" v-for="(shop, index) in result.recommend_shop_list" :key="index">
          <div class="history_shops">
            <div class="tex">{{shop.name}}</div>
            <div class="icon"><v-icon color="#9D9D9D">mdi-chevron-right</v-icon></div>
            <router-link v-bind:to="{ name: result.screen_id, query: { shop_id: shop.id, selected_shop: shop.name}}"></router-link>
          </div>
        </div>
      </div>
      <div v-else class="fontsize13 text-center py-6">
        まだお気に入り店舗のフォロー登録はありません。<br>
        ★をタップしてフォローしてみましょう。<br>
        <br>
        お店のお得なプッシュ通知を受け取ることができます。
      </div>
    </div>

    <p class="shop_select_title">{{head_text}}</p>
    <div style="border-bottom:1px solid #E6E6E6">
      <v-btn
        block
        depressed
        color="#fff"
        class="py-6 all_shops"
        v-if="all_shop_flg == 1"
        :to="{ name: result.screen_id, query: { shop_id: null, selected_shop: '全て'}}"
      >
        全てを表示する<v-icon color="#9D9D9D">mdi-chevron-right</v-icon>
      </v-btn>
    </div>

    <div class="ex_menu">
      <v-expansion-panels :flat="flat" v-model="panel[item.id]" :disabled="disabled" v-for="item in result.shop_list" :key="item.id">
        <v-expansion-panel class="select_wrap">
          <v-expansion-panel-header class="select_head px-4">
            {{item.name}}
            <template v-slot:actions><v-icon color="#9D9D9D">mdi-chevron-down</v-icon></template>
          </v-expansion-panel-header>

          <v-expansion-panel-content v-for="shopOrCategories in item.list" :key="shopOrCategories.id" class="">
            <div v-if="shopOrCategories.list == null" class="body-2 shopname_wrap">
              <v-btn class="px-0" block text @click.stop="toRouterPush(result.screen_id, shopOrCategories.id, shopOrCategories.name)">
                <v-row>
                  <v-col cols="10" class="shopname">
                    {{shopOrCategories.name}}
                  </v-col>
                  <v-col cols="2">
                    <v-icon color="#9D9D9D">mdi-chevron-right</v-icon>
                  </v-col>
                </v-row>
              </v-btn>
              <span v-if="shop_follow_flg" class="follow_stars">
                <transition enter-active-class="animate__animated animate__bounceIn">
                  <v-icon small color="#EED500" class="stars" v-show="shopOrCategories.follow_status == 1" @click.stop="followSet(0, shopOrCategories.name, shopOrCategories.id, shopOrCategories)">fas fa-star</v-icon>
                </transition>
                <transition enter-active-class="animate__animated animate__bounceIn">
                  <v-icon small color="#9D9D9D" class="stars" v-show="shopOrCategories.follow_status == 0" @click.stop="followSet(1, shopOrCategories.name, shopOrCategories.id, shopOrCategories)">fas fa-star</v-icon>
                </transition>
              </span>
            </div>
            <div v-if="shopOrCategories.list != null">
              <v-expansion-panels :flat="flat">
                <v-expansion-panel class="">
                  <v-expansion-panel-header class="select_head px-2 body-2">
                    {{shopOrCategories.name}}
                    <template v-slot:actions><v-icon color="#9D9D9D">mdi-chevron-down</v-icon></template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content v-for="shop in shopOrCategories.list" :key="shop.id" class="px-0 fontsize13">
                    <div class="body-2 shopname_wrap">
                      <v-btn class="px-0" block text @click.stop="toRouterPush(result.screen_id, shop.id, shop.name)">
                        <v-row>
                          <v-col cols="10">
                            {{shop.name}}
                          </v-col>
                          <v-col cols="2">
                            <v-icon color="#9D9D9D">mdi-chevron-right</v-icon>
                          </v-col>
                        </v-row>
                      </v-btn>
                      <template v-if="init.access_token">
                        <span v-if="shop_follow_flg" class="follow_stars">
                          <transition enter-active-class="animate__animated animate__bounceIn" leave-active-class="animate__animated animate__fadeOut">
                            <v-icon small color="#EED500" class="stars" v-show="shop.follow_status == 1" @click.stop="followSet(0, shop.name, shop.id, shop)">fas fa-star</v-icon>
                          </transition>
                          <transition enter-active-class="animate__animated animate__bounceIn" leave-active-class="animate__animated animate__fadeOut">
                            <v-icon small color="#9D9D9D" class="stars" v-show="shop.follow_status == 0" @click.stop="followSet(1, shop.name, shop.id, shop)">fas fa-star</v-icon>
                          </transition>
                        </span>
                      </template>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <v-overlay :value="loading" z-index=9999>
      <v-progress-circular :size="80" :width="2" class="maincolor" indeterminate></v-progress-circular>
    </v-overlay>

  </v-content>
</template>

<script>
export default {
  data: () => ({
      result: null,
      history_text: null,
      head_text: null,
      feature_id: null,
      disabled: false,
      panel: [],
      all_shop_flg: 1,
      booking_history_flg: false,
      digital_ticket_history_flg: false,
      shop_follow_flg: false,
      flat: true,
      loading: false,
  }),
  methods: {
    async followSet(flg, name, shop_id, shop){
      shop.follow_status = flg
      try {
        //this.loading = true
        /* 店舗フォローAPIを叩く */
        var req = { 
          'shop_id': shop_id,
          'flg': flg,
        }
        var follow_res = await this.apiCall('/user/favorite', req, 'post');
        if (!follow_res) return false;

        if(flg == 1){
          this.result.recommend_shop_list.push({ name: name, id: shop_id })
        }else{
          const index = this.result.recommend_shop_list.findIndex((v) => v.id === shop_id);
          this.result.recommend_shop_list.splice(index, 1);
        }

        shop.follow_status = flg

        this.loading = false
      } catch(e) {
        this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
        this.loading = false
      }
    },
    toRouterPush(screen_id, shop_id, selected_shop){
      this.$router.push({ name: screen_id, query: { shop_id: shop_id, selected_shop: selected_shop}})
    },
  },
  async created() {
    this.getUrlParam()
    this.init = this.storageGet('*')

    //リンク元によって、追加処理
    switch( this.$route.name ) {
      case 'notification_shops':
          this.head_text = "気になる店舗を選択する"
          this.feature_id = 'notification' 
          break;
      case 'calendar_shops':
          this.head_text = "気になる店舗を選択する"
          this.feature_id = 'calendar' 
          break;
      case 'coupon_shops':
          this.head_text = "クーポンの表示対象店舗を選択する"
          this.feature_id = 'coupon' 
          break;
      case 'stamp_shops':
          this.head_text = "スタンプを取得したい店舗を選択する"
          this.feature_id = 'stamp' 
          break;
      case 'talk_shops':
          this.head_text = "トークをしたい店舗を選択する"
          this.feature_id = 'talk' 
          break;
      case 'digital_ticket_shops':
          this.head_text = "チケット購入をしたい店舗を選択する"
          this.history_text = "最近購入した店舗"
          this.digital_ticket_history_flg = true
          this.feature_id = 'digital_ticket' 
          break;
      case 'movie_shops':
          this.head_text = "気になる店舗を選択する"
          this.feature_id = 'movie' 
          break;
      case 'booking_shops':
          this.head_text = "予約したい店舗を選択する"
          this.history_text = "最近予約した店舗"
          this.all_shop_flg = 0
          this.booking_history_flg = true
          this.feature_id = 'booking' 
          break;
      case 'info_shops':
          this.head_text = "お気に入り店舗を探してフォローする"
          this.followtitle = "フォロー"
          this.all_shop_flg = 0
          this.shop_follow_flg = true
          this.feature_id = 'info' 
          break;
      case 'photo_shops':
          this.head_text = "気になる店舗を選択する"
          this.feature_id = 'photo' 
          break;
      case 'menu_shops':
          this.head_text = "気になる店舗を選択する"
          this.feature_id = 'menu' 
          break;
    }
    var sub_menus = this.storageGet('menu_data') 

    sub_menus = sub_menus.sub_menus
    sub_menus.forEach( item => {
        if (location.pathname ==  this.getPath(item.url)) {
            this.feature_id = item.feature_id
        }
    });
    
    var shoplist_req = { params:{
      'sid': this.init.sid,
      'device_uid': this.init.device_uid,
      'device_id': this.init.device_id,
      'os': this.init.os,
      'key': this.init.key,
      'location': 0,
      'feature_id': this.feature_id,
    }}

    var res = await this.apiCall('/user/shops', shoplist_req)

    if (res.screen_id === 'notification') {
      res.screen_id = 'notification_list';
    }
    // console.log('response', res)

    this.$set(this, 'result', res)
    if (!res) return false;

  }

}
</script>

<style scoped>
/* vue css override */

.v-expansion-panel:not(:first-child)::after {
    border: none;
}

.v-expansion-panel::before {
    box-shadow: none;
}

.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)>.v-expansion-panel--active+.v-expansion-panel,
.v-expansion-panels:not(.v-expansion-panels--accordion):not(.v-expansion-panels--tile)>.v-expansion-panel--next-active {
    border-radius: 22px;
}

.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 12px 16px 12px !important;
  box-sizing: border-box;
}

/* original css */
.shop_select_title {
  margin: 0;
  width: 100%;
  padding: 5px 12px;
  background-color: #E4E4E4;
  color: #9D9D9D;
  font-size: 14px;
}
.select_wrap {
  border: none;
  background: #fff;
}
.select_head {
  font-size: 0.875rem;
  font-weight: bold;
  color: #333;
  border-bottom:1px solid #E6E6E6
}
.all_shops {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333;
  font-size: 0.875rem;
  line-height: 14px;
  font-weight: bold;
  box-sizing: border-box;
}
.v-expansion-panel-content >>> .v-expansion-panel-content__wrap {
  padding: 0 0 0 2rem !important;
}
.v-btn--block {
  text-align: left !important;
}
.history_shops {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  color: #9D9D9D;
  font-size:14px;
  line-height: 14px;
  font-weight: bold;
  padding: 12px 24px;
  box-sizing: border-box;
  margin-bottom: 0;
  position: relative;
}
.history_shops a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
}
.history_wrap {
  border-bottom: solid 1px #DEDEDF;
}
.history_wrap:last-of-type {
  border-bottom: none;
}
.shopname {
  text-align: left;
  padding-left: 14px;
  line-height: 1.9;
}
.shopname_wrap {
  position: relative;
}
.follow_stars {
    display: flex;
    text-align: center;
    align-items: center;
    position: absolute;
    top: -3px;
    right: 25px;
    width: 40px;
    height: 40px;
    overflow: hidden;
}
/* iOSの星がずれるのでiOSのみcss適用 */
_:lang(x)+_:-webkit-full-screen-document, .follow_stars { 
    top: 3px;
}
.stars {
    width: 40px;
    height: 40px;
    z-index: 10;
    position: absolute;
}
a {
    text-decoration: none !important;
    color: #333333;
    font-weight: bold;
}

.ex_menu >>> .v-btn:before {
  background-color:#fff;
}
</style>
