<template>
  <v-main class="ticket_error">

    <div class="px-3 mt-12">
      <div class="py-10 text-center">
        <svg class="svg_maincolor svg_maincolor_circle_fill svg_maincolor_rect_fill" xmlns="http://www.w3.org/2000/svg" width="89.588" height="79.257" viewBox="0 0 89.588 79.257"><g transform="translate(-1917 -6770.705)"><path d="M88.733,99.316,50.256,32.672a6.307,6.307,0,0,0-10.924,0L.855,99.316a6.307,6.307,0,0,0,5.462,9.46H83.271a6.307,6.307,0,0,0,5.462-9.46ZM84.181,103a1.018,1.018,0,0,1-.91.526H6.317a1.051,1.051,0,0,1-.91-1.577L43.883,35.3a1.051,1.051,0,0,1,1.82,0l38.477,66.644A1.017,1.017,0,0,1,84.181,103Z" transform="translate(1917 6741.186)" fill="#fc0bb1"/><rect width="5.256" height="28.031" transform="translate(1959.167 6794.602)" fill="#fc0bb1"/><circle cx="3.504" cy="3.504" r="3.504" transform="translate(1958.291 6827.889)" fill="#fc0bb1"/></g></svg>
        <p class="mt-5 title" style="font-family: 'Oswald', sans-serif !important;">Ticket Error</p>
      </div>

      <div class="mt-4 px-4 text-center">
        <h1 class="body-1 font-weight-bold mb-10">チケットの利用ができませんでした</h1>
        <p class="mt-4 mb-10 fontsize13">このチケットは利用することができません。<br>チケット内容をご確認ください。</p>
      </div>
    </div>

  </v-main>
</template>

<script>
export default {
  props: [],
  data() {
    return {
    };
  },
}
</script>

<style scoped>
</style>
